/** Grid layout*/
.grid-container {
  display: grid;
}
// Grid gaps
.gap-10 {
  gap: 10px;
}
// Grid cols
.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}
.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}
.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}
.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}
.grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}
.grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}
.grid-cols-7 {
  grid-template-columns: repeat(7, minmax(0, 1fr));
}
.grid-cols-8 {
  grid-template-columns: repeat(8, minmax(0, 1fr));
}
.grid-cols-9 {
  grid-template-columns: repeat(9, minmax(0, 1fr));
}
.grid-cols-10 {
  grid-template-columns: repeat(10, minmax(0, 1fr));
}
.grid-cols-11 {
  grid-template-columns: repeat(11, minmax(0, 1fr));
}
.grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}
.grid-cols-none {
  grid-template-columns: none;
}
.col-auto {
  grid-column: auto;
}
.col-span-1 {
  grid-column: span 1 / span 1;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.col-span-3 {
  grid-column: span 3 / span 3;
}

.col-span-4 {
  grid-column: span 4 / span 4;
}

.col-span-5 {
  grid-column: span 5 / span 5;
}

.col-span-6 {
  grid-column: span 6 / span 6;
}

.col-span-7 {
  grid-column: span 7 / span 7;
}

.col-span-8 {
  grid-column: span 8 / span 8;
}

.col-span-9 {
  grid-column: span 9 / span 9;
}

.col-span-10 {
  grid-column: span 10 / span 10;
}

.col-span-11 {
  grid-column: span 11 / span 11;
}
.col-span-12 {
  grid-column: span 12 / span 12;
}
.col-span-full {
  grid-column: 1 / -1;
}
.col-start-1 {
  grid-column-start: 1;
}
.col-start-2 {
  grid-column-start: 2;
}
.col-start-3 {
  grid-column-start: 3;
}
.col-start-4 {
  grid-column-start: 4;
}
.col-start-5 {
  grid-column-start: 5;
}
.col-start-6 {
  grid-column-start: 6;
}
.col-start-7 {
  grid-column-start: 7;
}
.col-start-8 {
  grid-column-start: 8;
}
.col-start-9 {
  grid-column-start: 9;
}
.col-start-10 {
  grid-column-start: 10;
}
.col-start-11 {
  grid-column-start: 11;
}
.col-start-12 {
  grid-column-start: 12;
}
.col-start-13 {
  grid-column-start: 13;
}
.col-start-auto {
  grid-column-start: auto;
}
// Grid rows
.grid-rows-1 {
  grid-template-rows: repeat(1, minmax(0, 1fr));
}
.grid-rows-2 {
  grid-template-rows: repeat(2, minmax(0, 1fr));
}
.grid-rows-3 {
  grid-template-rows: repeat(3, minmax(0, 1fr));
}
.grid-rows-4 {
  grid-template-rows: repeat(4, minmax(0, 1fr));
}
.grid-rows-5 {
  grid-template-rows: repeat(5, minmax(0, 1fr));
}
.grid-rows-6 {
  grid-template-rows: repeat(6, minmax(0, 1fr));
}
.grid-rows-none {
  grid-template-rows: none;
}
.row-auto {
  grid-row: auto;
}
.row-span-1 {
  grid-row: span 1 / span 1;
}
.row-span-2 {
  grid-row: span 2 / span 2;
}
.row-span-3 {
  grid-row: span 3 / span 3;
}
.row-span-4 {
  grid-row: span 4 / span 4;
}
.row-span-5 {
  grid-row: span 5 / span 5;
}
.row-span-6 {
  grid-row: span 6 / span 6;
}
.row-span-full {
  grid-row: 1 / -1;
}

@include responsive--mobile {
  @for $i from 0 through 12 {
    .sm\:grid-cols-#{$i} {
      grid-template-columns: repeat($i, minmax(0, 1fr));
    }
    .sm\:grid-cols-none {
      grid-template-columns: none;
    }
  }
  @for $i from 0 through 6 {
    .sm\:grid-rows-#{$i} {
      grid-template-rows: repeat($i, minmax(0, 1fr));
    }
    .sm\:grid-rows-none {
      grid-template-rows: none;
    }
  }
}
@include responsive--tablet {
  @for $i from 0 through 12 {
    .md\:grid-cols-#{$i} {
      grid-template-columns: repeat($i, minmax(0, 1fr));
    }
    .md\:grid-cols-none {
      grid-template-columns: none;
    }
  }
  @for $i from 0 through 6 {
    .md\:grid-rows-#{$i} {
      grid-template-rows: repeat($i, minmax(0, 1fr));
    }
    .md\:grid-rows-none {
      grid-template-rows: none;
    }
  }
}
@include responsive--desktop {
  @for $i from 0 through 12 {
    .lg\:grid-cols-#{$i} {
      grid-template-columns: repeat($i, minmax(0, 1fr));
    }
    .lg\:grid-cols-none {
      grid-template-columns: none;
    }
  }
  @for $i from 0 through 6 {
    .lg\:grid-rows-#{$i} {
      grid-template-rows: repeat($i, minmax(0, 1fr));
    }
    .lg\:grid-rows-none {
      grid-template-rows: none;
    }
  }
}

@include responsive--xlg {
  @for $i from 0 through 12 {
    .xlg\:grid-cols-#{$i} {
      grid-template-columns: repeat($i, minmax(0, 1fr));
    }
    .xlg\:grid-cols-none {
      grid-template-columns: none;
    }
  }
  @for $i from 0 through 6 {
    .xlg\:grid-rows-#{$i} {
      grid-template-rows: repeat($i, minmax(0, 1fr));
    }
    .xlg\:grid-rows-none {
      grid-template-rows: none;
    }
  }
}

/** Grid layout*/
