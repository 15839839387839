@import '../../utils/variables';
@import '../../utils/boxshadow';
@import '../../utils/filters';
@import '../../utils/mixin';
@import '../../utils/responsive';
@import '../../utils/textstyle';

@import '../../presets/buttons';

.tutorial-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: $color__white;
  position: relative;

  // &:not(&.unpadding) {
  //   padding: 56px 0 0;
  // }

  &.business {
    & > .tutorial-content-container {
      // margin-bottom: 88px; // button-container height
      overflow-y: auto;

      & > .tutorial-content {
        & > .tutorial-question {
          @include font(28px, $color__default, 500, normal);
        }

        .tutorial-kakao-channel {
          &-content-container {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 10px;
            padding-bottom: 20px;
            border-bottom: 1px solid #F4F4F4;
            margin: 0 0 20px;
            padding-inline: 20px;

            &:last-child {
              border-bottom: unset;
              margin-bottom: 80px;
            }
          }

          &-title {
            margin: 0;
            @include font(16px, $color__default, 700, normal);
          }

          &-description {
            @include font(14px, $color__grey_66, 400, normal);
          }
        }
      }
    }

    & > .button-container {
      border-top: 0;
    }
  }

  &.kakao-channel {
    & > .tutorial-content-container {
      // margin-bottom: 88px; // button-container height
      overflow-y: auto;

      & > .tutorial-content {
        & > .tutorial-question {
          @include font(28px, $color__default, 500, normal);
        }

        .tutorial-kakao-channel {
          &-content-container {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 10px;
            margin: 0 0 20px;
            padding-inline: 20px;
          }

          &-title {
            margin: 0;
            @include font(16px, $color__default, 700, normal);
          }

          &-tabletCustomInformationMessage {
            @include font(14px, $color__grey_66, 400, normal);
          }
        }
      }
    }

    & > .button-container {
      border-top: 0;
    }
  }

  & > .tutorial-content-container {
    // 여기 padding-inline 넣지 않기
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    height: calc(100vh - 121.4px);
    // margin-top: 20px;
    //margin-bottom: 109px;

    & > .tutorial-content {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      height: 100%;

      & .tutorial-subquestion-head,
      & .tutorial-subquestion-body {
        padding-inline: 20px;
      }
      & > .tutorial-content-title {
        align-self: flex-start;
        min-height: 151px;
        margin-top: 20px;
        margin-bottom: 20px;

        & > .tutorial-question {
          @include font__title_28_m;
          padding-inline: 20px;
          margin-bottom: 6px;
          white-space: pre-line;
        }
        & > .tutorial-note {
          @include font__body_md;
          margin-bottom: 20px;
          white-space: pre-line;
          padding-inline: 20px;
          color: #8a8395;
          &.before {
            margin-bottom: 6px;
          }
        }
      }

      & > .naverkakao {
        height: 100%;
      }

      & > .tutorial-interaction {
        // display: flex;
        justify-content: flex-start;
        width: 100%;
        //padding-bottom: 20px;
        //overflow: auto;
        & .row {
          padding-inline: 20px;
        }
        .facilities-list {
          overflow: auto;
        }
        .wrapper {
          padding-inline: 20px;
          padding-bottom: 20px;
          gap: 20px 8px;
          // height: 100%;

          & label.facilitiesCheck i {
            padding: 8px 14px;
          }
        }
        &.button {
          display: inline-flex;
          padding-inline: 20px;
          flex-direction: column;
          gap: 10px;
          & > button {
            height: 64px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            flex-direction: column;
            // width: 100%;
            @include tblm-button-base;
            @include font__title_16;
            color: $color__grey_600;
            background-color: $color__white;
            border: 1px solid $color__lgrey_300;
            &.active {
              @include font__title_16_b;
              position: relative;
              background-color: $color__p_purple_light;
              border-color: $color__p_purple;
              color: $color__p_purple;
            }
          }
        }
        > button:active {
          @include font__title_16_b;
          position: relative;
          background-color: $color__lgrey_100;
          border-color: $color__grey_900;
          color: $color__grey_900;
        }
        &.checkbox {
          //padding-bottom: 20px;
          flex-grow: 0;
          overflow: overlay;
          overflow: auto;
        }
        &.select {
          margin-top: auto;
        }
        &.until {
          display: flex;
          flex-direction: column;
          // justify-content: center;
          // margin-top: 64px;
          row-gap: 16px;
          height: -webkit-fill-available;
          height: 100%;
          @include font__title_20_m;
          & > div.row {
            display: flex;
            align-items: center;
            & > div.selectbox {
              width: 232px;
              height: 40px;
              border-radius: 4px;
              border: 1px solid $color__lgrey_300;
              margin-right: 12px;
              padding: 9px 12px;
              @include font__body_5;
              line-height: 20px;
              font-family: Roboto;
              &.select-placeholder {
                color: $color__grey_600;
              }
              & > svg {
                float: right;
                height: 100%;
                // width: 20px;
                // height: 20px;
              }
            }
          }
        }
        &.naverkakao {
          display: flex;
          margin-bottom: 80px;
        }
      }
    }
    &.essential-tutorial-content-container {
      .tutorial-note:empty {
        display: none;
      }
      .tutorial-interaction {
        // height: calc(100% - 151px);
        height: 100%;
        // overflow: auto;
        &:has(.food-list-wrapper) {
          margin-top: -20px;
        }
      }
    }
  }
  & > .button-container {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    display: flex;
    align-items: center;
    gap: 8px;
    // margin: 20px -20px 0 -20px;
    width: 100%;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
    padding: 20px;
    z-index: 7;
    & > button {
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      @include font__subtitle2_b;
      &.prev {
        @include tblm-button-white;
      }
      &.next {
        @include tblm-button-purple;
        color: $color__white !important;
        padding: 0;
      }
    }
  }
  &.unfixed {
    padding-bottom: 0;
    & > .tutorial-content-container {
      height: 100%;
      & > .tutorial-content {
        display: flex;
        height: 100%;
        overflow: overlay;
        overflow: auto;
      }
    }
  }
  &.fulled {
    height: auto;
    padding-bottom: 0;
    & > .tutorial-content-container {
      height: auto;
      & > .tutorial-content {
        display: flex;
      }
    }
    & > .button-container {
      position: sticky;
      //bottom: unset;
      display: flex;
      align-items: center;
      gap: 8px;
      margin: 20px auto 0;
      bottom: 0;
    }
  }
  &.nobutton {
    & > .tutorial-content-container {
      //height: 100%;
    }
  }
}

.reservation-nono {
  cursor: pointer;
  text-align: center;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: $color__default;
  text-decoration: underline;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  padding-bottom: 13px;
}
