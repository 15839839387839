@import '../../styles/utils/variables';
@import '../../styles/utils/boxshadow';
@import '../../styles/utils/filters';
@import '../../styles/utils/mixin';
@import '../../styles/utils/responsive';
@import '../../styles/utils/textstyle';

@import '../../styles/presets/buttons';

.shadow-container {
  height: 10px;
  margin: -10px -20px 0;
  width: 100vw;

  &.scroll {
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  }
}
