@import '../../../../styles/utils/variables';
@import '../../../../styles/utils/boxshadow';
@import '../../../../styles/utils/filters';
@import '../../../../styles/utils/mixin';
@import '../../../../styles/utils/responsive';
@import '../../../../styles/utils/textstyle';

@import '../../../../styles/presets/buttons';

.reservation-top-banner {
  padding: 30px 10px 10px;
  background-color: $color__white;
  color: $color__white;

  &-provider {
    position: relative;
    padding: 18px 18px 20px;
    border-radius: 8px;
    background: linear-gradient(96deg, #312ece 2.01%, #5a57ff 98.65%);
  }

  &-label {
    padding: 3px 5px;
    border-radius: 4px;
    background: rgba(41, 18, 87, 0.2);
    font-size: 10px;
    font-weight: 700;
  }

  &-title {
    margin: 6px 0 9px;
    white-space: break-spaces;
    color: inherit;
    line-height: 25px;
  }

  &-tabletCustomInformationMessage {
    color: inherit;
    line-height: normal;
  }

  &-calendar-icon {
    position: absolute;
    top: -18px;
    right: 18px;
    z-index: 1;
  }

  &-rectangle {
    &-big {
      position: absolute;
      top: 0;
      right: 0;
    }

    &-small {
      position: absolute;
      top: 91px;
      right: 65px;
    }
  }
}
