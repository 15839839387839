.custom-radio-wrapper {
  display: flex;
  flex-direction: column;
  padding: 24px 20px 20px;

  & > .custom-radio-item {
    width: 100%;
    border-bottom: 1px solid $color__lgrey_100;
    & > label {
      display: flex;
      padding: 16px 4px;
      justify-content: space-between;
      align-items: center;
      flex-direction: row-reverse;
      cursor: pointer;
    }
    &:last-child {
      border: none;
    }
  }
}
