@import '../../styles/utils/variables';
@import '../../styles/utils/boxshadow';
@import '../../styles/utils/filters';
@import '../../styles/utils/mixin';
@import '../../styles/utils/responsive';
@import '../../styles/utils/textstyle';

@import '../../styles/presets/buttons';

.header-sub-wrapper {
  position: sticky;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 20px;
  left: 0;
  right: 0;
  z-index: 2;
  background: $color__white;

  &.is-border {
    border-bottom: 1px solid $color__lgrey_100;
  }

  &.header-sub {
    z-index: 2;
    top: 0;
    & > .close {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      padding: 16px 0;
    }
    & > .title {
      display: flex;
      width: 100%;
      padding: 0 0 24px 0;
      &.remove-br {
        & br {
          display: none;
        }
      }
    }

    &.is-fixed {
      & > .close {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 20px;
        display: flex;
        align-items: center;
        cursor: pointer;
      }
      & > .title {
        display: block;
        // justify-content: center;
        max-width: 14em;
        width: unset;
        overflow-x: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        word-break: break-all;
        padding: 17.5px 0;
        &.is-scroll-title-visible {
          opacity: 0;
        }
      }
    }
    &.is-fixed + .month-calendar-wrapper {
      .calendar-sticky-header {
        padding-top: 24px;
      }
    }
  }
}
.header-placeholder {
  width: 100%;
  height: 56px;
  background-color: #fff;
}
