@import '../../styles/utils/variables';
@import '../../styles/utils/boxshadow';
@import '../../styles/utils/filters';
@import '../../styles/utils/mixin';
@import '../../styles/utils/responsive';
@import '../../styles/utils/textstyle';

@import '../../styles/presets/buttons';

// TutorialSelector styles
.tutorial-selector {
  padding: 22px 20px 20px;
  // width: calc(100% + 40px);
  height: 320px;
  border-radius: 16px;
  box-shadow: 0 -8px 16px rgba(0, 0, 0, 0.08);
  & > .title {
    white-space: pre-line;
    margin-bottom: 24px;
    @include font__header_title;
  }

  .picker-container {
    margin-bottom: 20px;
  }
}
