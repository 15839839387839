@import '../../styles/utils/variables';
@import '../../styles/utils/boxshadow';
@import '../../styles/utils/filters';
@import '../../styles/utils/mixin';
@import '../../styles/utils/responsive';
@import '../../styles/utils/textstyle';

@import '../../styles/presets/buttons';

// ListFilter styles
.list__filter_content {
  width: 100%;
  margin: 0;
  padding: 0;
  color: $color__black;
  &.list__filter_group_list {
    // min-height: 162px;
    // min-height: calc(100vh - 89px);
    padding-bottom: 89px;
    overflow: overlay;
    overflow: auto;
    & > li {
      border-top: 1px solid $color__lgrey_100;
      &:first-child {
        border-top: 0;
      }
      & > label {
        display: flex;
        padding: 16px 4px;
        justify-content: space-between;
        align-items: center;
        flex-direction: row-reverse;
        cursor: pointer;
        & > i {
          margin-left: auto;
        }
        & > input[readonly] + i {
          opacity: 0;
        }
      }
    }
  }
  &.list__filter_status_list {
    margin-bottom: 48px;
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    row-gap: 16px;
    & > label {
      margin-right: 16px;
      flex-grow: 0;
      flex-shrink: 0;
      cursor: pointer;
      & > input {
        display: none;
      }
    }
  }
}
