@import '../../utils/variables';
@import '../../utils/boxshadow';
@import '../../utils/filters';
@import '../../utils/mixin';
@import '../../utils/responsive';
@import '../../utils/textstyle';

@import '../../presets/buttons';

.tutorial-page.kakao-channel {
  .tutorial-content {
    .tutorial-question {
      &.unmargin {
        margin-bottom: 0;
      }
    }

    .tutorial-note {
      &.after {
        margin: 6px 0 23px;
        @include font(14px, $color__grey_600, 400, normal);
      }
    }
  }

  .skip-container {
    display: flex;
    justify-content: center;
    position: fixed;
    z-index: 7;
    left: 0;
    right: 0;
    bottom: 88px;
    padding: 10px 10px 0 10px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%);

    .skip-link {
      @include font(14px, $color__p_purple, 700, normal);
      text-decoration-line: underline;
      cursor: pointer;
    }
  }
}
