.agreement-doc {
  display: flex;
  position: fixed;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  overflow: hidden;
  z-index: 4;
  background-color: $color__white;

  .agreement-doc-inner {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 20px 24px 20px;
    & > svg {
      // min-height: 60px;
      margin: 15px 0 15px auto;
    }
    & * {
      white-space: pre-line;
    }
    & > .agreement-doc-title {
      @include font__title_32_sb;
      margin-bottom: 48px;

      &.agreementHistory-title {
        @include font__title_20_sb;
        margin-bottom: 24px;
      }
    }
    & .agreement-doc-body {
      flex: 1;
      width: 100%;
      overflow: overlay;
      overflow: auto;
      @include font(15px, $color__default, 400, 22px);
      span.h2 {
        font-weight: 600;
        display: block;
        margin-top: 24px;
        margin-bottom: 5px;
        color: $color__default;
      }
      table {
        margin-top: 24px;
        margin-bottom: 24px;
        border-collapse: collapse;

        tr {
          &:first-child {
            border-top: 1px solid $color__lgrey_100;
          }
          border-bottom: 1px solid $color__lgrey_100;

          th {
            @include font(15px, $color__default, 600, 22px);
            padding: 10px;
            background-color: $color__bg_98;
            text-align: center;

            &:not(&:first-of-type) {
              border-left: 1px solid $color__lgrey_100;
            }
          }

          td {
            @include font(11px, $color__default, 500, 12px);
            padding: 10px;

            &:not(&:first-of-type) {
              border-left: 1px solid $color__lgrey_100;
            }

            @media (min-width: 360px) {
              @include font(12px, $color__default, 500, 14px);
            }

            @media (min-width: 414px) {
              @include font(13px, $color__default, 500, 16px);
            }
          }
        }
      }

      .dotted {
        @include font(12px, $color__default, 400, 14px);
        position: relative;
        display: flex;
        align-items: flex-start;

        @media (min-width: 375px) {
          @include font(15px, $color__default, 400, 22px);
        }

        &::before {
          content: '・';
          height: 100%;
          width: 30px;
          display: flex;
        }
      }
    }
    .privacy {
      table tr > td {
        // &:first-child {
        //   padding-inline: 2px;
        //   min-width: 76px !important;
        // }
        // &:not(&:first-of-type) {
        //   width: 32%;
        // }
      }
    }

    .third,
    .marketing {
      table {
        table-layout: fixed;
        width: 100%;

        tr {
          display: flex;
          align-items: stretch;

          & > td {
            align-items: center;
            display: flex;
            justify-content: space-around;
            border-left: 1px solid $color__lgrey_100;

            &:first-child {
              @include font(15px, $color__default, 600, 22px);
              width: 112px !important;
              background-color: $color__bg_98;
              text-align: center;
              border-left: 0;
            }

            &:not(&:first-of-type) {
              width: calc(100% - 112px);
              justify-content: start;
            }
          }
        }
      }
    }

    .tos span.h2:first-of-type,
    .privacy span.h2:first-of-type,
    .third span.h2:first-of-type:not(.privacy-and-third > .third span.h2),
    .marketing span.h2:first-of-type {
      margin-top: 0;
    }

    .privacy-and-third > .privacy span.h2 {
      margin-top: 0;
    }
  }
}
