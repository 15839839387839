@import '../../styles/utils/variables';
@import '../../styles/utils/boxshadow';
@import '../../styles/utils/filters';
@import '../../styles/utils/mixin';
@import '../../styles/utils/responsive';
@import '../../styles/utils/textstyle';

@import '../../styles/presets/buttons';

.icon-text-button {
  display: flex;
  height: 36px;
  padding: 8px 14px;
  align-items: center;
  border-radius: 40px;
  background-color: $color__lgrey_100;
  border: 0;
  &:has(svc) {
    min-width: 70px;
  }

  color: $color__default;
  @include font__body_md_m;

  &:active {
    background-color: $color__lgrey_300;
  }

  &:disabled {
    opacity: 0.5;
  }

  &.purple {
    background-color: $color__p_purple_light;
    color: $color__p_purple;

    &:active {
      background-color: #ded0ff;
    }
  }
}
