@import '../../styles/utils/variables';
@import '../../styles/utils/boxshadow';
@import '../../styles/utils/filters';
@import '../../styles/utils/mixin';
@import '../../styles/utils/responsive';
@import '../../styles/utils/textstyle';

@import '../../styles/presets/buttons';

.setting {
  &-container {
    overflow-y: scroll;
    min-height: calc(100% - 106px);
    scroll-snap-type: y mandatory;
    scroll-behavior: smooth;
    margin-top: 106px;
    & > .item-container {
      padding: 0 20px;

      & > .onoff-item-container {
        padding: 20px 0;

        &:not(:last-child) {
          border-bottom: 1px solid $color__lgrey_100;
        }
      }

      & > .setting-item-container {
        padding: 12px 0;

        &:not(:last-child) {
          border-bottom: 1px solid $color__lgrey_100;
        }
      }
    }
  }
  &-group-title {
    @include font__s_small_m;
    display: flex;
    padding: 5px 20px;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    background: $color__lgrey_100;
  }
}
