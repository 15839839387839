@import '../../styles/utils/variables';
@import '../../styles/utils/boxshadow';
@import '../../styles/utils/filters';
@import '../../styles/utils/mixin';
@import '../../styles/utils/responsive';
@import '../../styles/utils/textstyle';

@import '../../styles/presets/buttons';

.footer-sub-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding: 20px;
  width: 100%;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
  margin: 0 auto;

  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;

  & .flex-container .tblm-button-normal {
    min-width: 68px;
    padding: 0;
  }

  &.is-border {
    border-top: 1px solid $color__lgrey_100;

    & > .font__subtitle {
      padding-right: calc(24px - 8px);
    }
  }

  &.no-transparent {
    background-color: $color__white;
  }
}
.footer-area {
  // position: relative;
  width: 100%;
  // margin-top: -20px;
}

button.footer {
  min-width: 68px;
}
