@import '../../../styles/utils/variables';
@import '../../../styles/utils/boxshadow';
@import '../../../styles/utils/filters';
@import '../../../styles/utils/mixin';
@import '../../../styles/utils/responsive';
@import '../../../styles/utils/textstyle';

@import '../../../styles/presets/buttons';

.recent-list-wrapper {
  display: flex;
  flex-direction: column;
  & > .recent-list-item {
    display: flex;
    padding: 18px 4px;
    align-items: center;
    cursor: pointer;
    gap: 12px;
    border-bottom: 1px solid $color__lgrey_100;

    & > div + svg {
      margin-left: auto;
    }
    &:last-child {
      border: none;
    }
  }
}
