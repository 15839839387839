html,
body {
  width: 100%;
  padding: 0;
  margin: 0;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  padding: 0;
}

ul {
  list-style: none;
}

em {
  font-style: normal;
}
