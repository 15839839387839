@import '../../../styles/utils/variables';
@import '../../../styles/utils/boxshadow';
@import '../../../styles/utils/filters';
@import '../../../styles/utils/mixin';
@import '../../../styles/utils/responsive';
@import '../../../styles/utils/textstyle';

@import '../../../styles/presets/buttons';

.table-edit-wrapper {
  .table-container {
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:first-child {
      padding-top: 0;
    }

    &:not(&:last-of-type) {
      border-bottom: 1px solid $color__lgrey_50;
    }

    &:last-of-type {
      padding-bottom: 0;
    }

    .table-info-box {
      width: calc(100% - 56px);

      .table-name {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: 100%;
        width: fit-content;
        margin-bottom: 16px;
        // font-weight: 600;
      }
    }

    .table-enabled-controller {
      width: 56px;
    }

    &.disable {
      .table-name {
        color: $color__grey_600;
      }

      .table-info {
        color: $color__grey_600;
      }
    }
  }
}
