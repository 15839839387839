@import '../../styles/utils/variables';
@import '../../styles/utils/boxshadow';
@import '../../styles/utils/filters';
@import '../../styles/utils/mixin';
@import '../../styles/utils/responsive';
@import '../../styles/utils/textstyle';

@import '../../styles/presets/buttons';

// TutorialTable styles
.tutorial-page.unfixed {
  position: relative;
  // margin-top: 108.67px;
  padding: 0;
  width: 100%;
  // height: calc(100vh - 56px);
  display: block;
  overflow: overlay;
  overflow: auto;
  border-radius: 0;

  // & .tutorial-content-title-container {
  //   position: sticky;
  //   background-color: $color__white;
  //   top: 0px;
  //   z-index: 7;
  // }
  // & .tutorial-content-stepper-container {
  //   position: sticky;
  //   background-color: $color__white;
  //   top: 60px;
  //   z-index: 7;
  // }

  scroll-behavior: smooth;
  // & ~ .fake {
  //   &:before {
  //     content: '';
  //     position: absolute;
  //     top: 0;
  //     right: 0;
  //     left: 0;
  //     height: 56px;
  //     display: block;
  //     background-color: $color__white;
  //   }
  // }

  & > .tutorial-table-content__head {
    z-index: 2;
    padding: 20px;
    width: 100%;
    display: block;
    background-color: $color__white;
    & > .tutorial-question {
      @include font__title_28_m;
      height: 151px;
      font-weight: 600;
      white-space: pre-line;
    }
    &:before {
      content: '';
      position: absolute;
      top: -56px;
      right: 0;
      left: 0;
      height: 56px;
      display: block;
      background-color: $color__white;
    }
  }
  & > .tutorial-table-content__body {
    padding: 20px 0px 85px;
    margin-bottom: -200px;
    row-gap: 32px;
    background-color: $color__lgrey_50;
    display: flex;
    flex-direction: column;
    min-height: calc(100% - 299.67px);
    & > button {
      &.addTableGroup {
        @include font__subtitle2_b;
        width: calc(100% - 40px);
        margin-inline: 20px;
        margin-top: -16px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid $color__lgrey_300;
        border-radius: 4px;
        background-color: $color__white;
        // z-index: 6;
        & > svg {
          margin-right: 2px;
        }
      }

      &:first-child {
        margin-top: 0;
      }
    }
    .table_group {
      margin-right: 20px;
      // margin-bottom: 32px;
      margin-left: 20px;
      display: flex;
      flex-direction: column;
      border-radius: 8px;
      background-color: $color__white;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.04);

      padding-inline: 16px;
      &:first-child {
        margin-top: 0;
      }
      // &:nth-last-of-type(2) {
      //   margin-bottom: 16px;
      // }
      & > .table_group_head {
        @include font__title_20_b;
        position: sticky;
        top: 108px;
        margin-block: 16px;
        height: 36px;
        display: flex;
        align-items: center;
        flex-direction: row;
        background-color: $color__white;
        & > svg {
          margin-left: 5px;
        }
        & > span {
          flex: 1;
        }
        &[stuck] {
          & > svg {
            margin-left: auto;
          }
          z-index: 2;
          margin: 0 -36px;
          padding: 14px 20px;
          height: unset;
          box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
        }
      }
      & > .table_group_addtable {
        @include tblm-button-color(#f2eefc, $color__p_purple);
        font-weight: 500;
        margin: 16px 0;
        width: auto;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 40px;
        & > svg {
          margin-right: 4px;
        }
      }
      & > .table_table {
        padding-bottom: 16px;
        &:last-of-type {
          padding-bottom: 0;
        }
        & > .new-table-info-wrapper {
          padding: 20px;
          margin: 0px;
          border-radius: 8px;
          background-color: $color__lgrey_100;

          & > .label {
            margin-bottom: 6px;
          }
        }
      }
    }
  }

  & > .tutorial-table-content__footer {
    position: sticky;
    bottom: 0px;
    width: 100%;
    
  }

    &>.button-container {
      margin: -36px auto 0;
      padding: 20px;
      display: flex;
      align-items: center;
      gap: 8px;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
      z-index: 6;
      width: 100%;
      align-self: end;
  
      &>button {
        @include font__subtitle1_b;
        @include tblm-button-color($color__p_purple, $color__white);
        padding: 0;
        height: 48px;
        display: flex;
        align-items: center;
        flex: 1;
        justify-content: center;
        color: $color__white !important;
      }
    }
  
    &>button {
      &.scrollbottom {
        @include tblm-button-floating($color__p_purple_dark, $color__black);
        position: sticky !important;
        bottom: 140px;
        margin: 0px auto 0px calc(100% - 80px);
  
        &>svg {
          filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(69deg) brightness(104%) contrast(104%);
        }
  
        &.up>svg {
          transform: rotate(180deg);
        }
      }
    }
}
