@import '../../styles/utils/variables';
@import '../../styles/utils/boxshadow';
@import '../../styles/utils/filters';
@import '../../styles/utils/mixin';
@import '../../styles/utils/responsive';
@import '../../styles/utils/textstyle';

@import '../../styles/presets/buttons';

.kakao-input {
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 8px 14px;
  background-color: $color__lgrey_100;
  border: 1px solid $color__lgrey_100;
  border-radius: 4px;
  cursor: text;
  transition: border 100ms;

  &:disabled {
    opacity: 1 !important;
  }

  &.disabled {
    background-color: $color__white;
    cursor: not-allowed;
  }

  &:focus-within {
    border: 1px solid $color__p_purple;
  }

  &-field {
    width: 100%;
    border: 0;
    background-color: inherit;
    @include font(14px, $color__default, 500, normal);
    caret-color: $color__p_purple;
    cursor: inherit;

    &:focus {
      outline: 0;
    }

    &::placeholder {
      @include font(14px, $color__grey_600, 400, normal);
    }
  }

  &-clear-btn {
    border: 0;
    background-color: inherit;
    line-height: 0;
    cursor: pointer;

    // & > svg {
    //   min-width: fit-content;
    //   min-height: fit-content;
    // }
  }
}