@import '../../styles/utils/variables';
@import '../../styles/utils/boxshadow';
@import '../../styles/utils/filters';
@import '../../styles/utils/mixin';
@import '../../styles/utils/responsive';
@import '../../styles/utils/textstyle';

@import '../../styles/presets/buttons';

.calendar-sticky-header {
  position: sticky;
  background-color: $color__white;
  padding: 8px 20px;

  .view-date-text {
    padding-bottom: 16px;
    .icon-arrow-down {
      vertical-align: middle;
      width: 16px;
      height: 16px;
      margin-left: 4px;
    }
  }

  .week-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 14px;

    .week-header-item {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $color__default;
      margin-bottom: 8px;
      &:first-child {
        color: #eb493f;
      }
      &:nth-child(7) {
        color: #2150c4;
      }
    }
  }
}

.calendar-container {
  padding: 0 20px;

  &:last-child {
    padding-bottom: 20px;
  }
}

.calendar-month {
  padding-bottom: 8px;
}

.calendar-week {
  display: flex;
  justify-content: space-between;
  gap: 14px;
  border-top: 1px solid $color__lgrey_50;
  padding-bottom: 20px;

  .management-calendar-day {
    flex: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 4px;
    min-height: calc(58px + 4px);
    cursor: pointer;
    padding: 4px 0px 8px;

    .date-box {
      min-width: 28px;
      min-height: 28px;
      // width: 28px;
      // height: 28px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $color__grey_600;

      &.sunday,
      &.holiday {
        color: #f7b6b2;
      }
      &.saturday {
        color: #b2c7fa;
      }
      &.selected {
        color: $color__white;
        background-color: $color__p_purple;
        border-radius: 50%;
      }

      &.today {
        border: 1px solid #333;
        border-radius: 100%;
      }
    }
    &.active {
      .date-box {
        color: #333;
        &.sunday,
        &.holiday {
          color: #eb493f;
        }
        &.saturday {
          color: #2150c4;
        }
      }
    }
    .order-info {
      color: $color__grey_66;
      text-align: center;
      width: 100%;
      padding-inline: 2px;
      .onoff-tag {
        display: flex;
        justify-content: center;
        align-items: center;
        // padding: 2px 8px;
        // width: 32px;
        height: 18px;
        border-radius: 100px;
        background: none;
        border: 1px solid $color__grey_600;
        color: $color__grey_66;
        &.on {
          background: $color__p_purple;
          color: $color__white;
          border: none;
        }
      }
    }
  }

  &:last-child {
    padding: 0;
  }
}
