@import '../../styles/utils/variables';
@import '../../styles/utils/boxshadow';
@import '../../styles/utils/filters';
@import '../../styles/utils/mixin';
@import '../../styles/utils/responsive';
@import '../../styles/utils/textstyle';

@import '../../styles/presets/buttons';

.food-list-wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  // align-items: flex-end;
  margin: 20px auto 0;
  border-radius: 16px 16px 0 0;
  background: $color__white;
  filter: drop-shadow(0 -3px 12px rgba(00, 0, 0, 0.08));

  .food-title {
    @include font__header_title;
    margin: 22px 20px 12px;
  }
  .food-list {
    //position: absolute;
    //right: 0;
    //bottom: 0;
    //left: 0;
    width: 100%;
    // max-width: 375px;
    height: calc(100vh - 365.5px);
    //max-height: calc(100% - 250px);
    display: flex;
    flex-direction: column;
    padding: 20px 20px 70px;
  }
}
