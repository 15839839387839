@import '../../styles/utils/variables';
@import '../../styles/utils/boxshadow';
@import '../../styles/utils/filters';
@import '../../styles/utils/mixin';
@import '../../styles/utils/responsive';
@import '../../styles/utils/textstyle';

@import '../../styles/presets/buttons';

.table-view-container {
  padding: 0 20px 20px;
  background-color: $color__lgrey_50;
  min-height: calc(100% - 60px);

  .reset-button {
    padding: 11px 8px;
    margin-block: 20px;
    text-align: right;
    @include font__body_6;
    color: $color__grey_66;
    text-decoration-line: underline;
  }
  .footer-button {
    width: 100%;
  }
}
