@import '../../../../styles/utils/variables';
@import '../../../../styles/utils/boxshadow';
@import '../../../../styles/utils/filters';
@import '../../../../styles/utils/mixin';
@import '../../../../styles/utils/responsive';
@import '../../../../styles/utils/textstyle';

@import '../../../../styles/presets/buttons';

.agreementHistory {
  &__check_list {
    padding: 24px 0px;

    label.tblm-rc {
      display: flex;
      align-items: center;
      gap: 24px;

      & section {
        h2 {
          @include font__title_20_sb;
        }

        span.agreement-day {
          @include font__small_m;
          color: $color__grey_66;
          padding-top: 4px;
        }

        em {
          display: block;
          text-decoration: underline;
          padding-top: 12px;
          @include font__small_sb;
        }
      }
    }
  }
}
