@import 'utils/variables';
@import 'utils/boxshadow';
@import 'utils/filters';
@import 'utils/mixin';
@import 'utils/responsive';
@import 'utils/textstyle';

@import 'presets/buttons';

// tailwind
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

// Presets
@import './presets/grid';
@import './presets/flex';
@import './presets/animations';
@import './presets/buttons';
@import './presets/font';

// base
@import './base/reset';
@import './base/common';
@import './base/typograpy';

// components
@import './components/buttons';
@import './components/chipandtag';
@import './components/selection';
@import './components/modal';

// components-hook-from
@import './components/hook-form/ControlledRadioBox';
@import './components/hook-form/ControlledCheckBox';

// lib

// fonts
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@400;500;600;700&family=Roboto:wght@100;300;400;500;700;900&display=swap');

// layout

// pages
@import './pages/list';

// reservation
@import './pages/reservation/accept/accept';
@import './pages/reservation/user-search/user-search';

// settings
@import './pages/setting/policy';
@import './pages/setting/schedule-management';
@import 'pages/setting/kakao-setting';

#root {
  display: flex;
  justify-content: center;
  background-color: $color__white;
  -webkit-tap-highlight-color: transparent !important;
  width: 100%;
  height: 100%;
  //-webkit-overflow-scrolling: touch; /* enables “momentum” (smooth) scrolling */
}
.main-container-desktop {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
  box-shadow: -1px 0px 0px 0px rgba(0, 0, 0, 0.1), 1px 0px 0px 0px rgba(0, 0, 0, 0.1);

  &:has(.reservation-detail-wrapper) {
    background: #f4f4f4;
  }
}

:root {
  --rsbs-backdrop-bg: rgba(0, 0, 0, 0.6);
  --rsbs-bg: #fff;
  --rsbs-handle-bg: transparent;
  --rsbs-max-w: auto;
  --rsbs-ml: env(safe-area-inset-left);
  --rsbs-mr: env(safe-area-inset-right);
  --rsbs-overlay-rounded: 16px;
  --rsbs-content-h: 100%;
}

div[data-rsbs-header] {
  box-shadow: unset;
}

div[data-rsbs-footer] {
  box-shadow: unset;
}

.without-header {
  height: calc(100vh - 118px);
}
