@import '../../styles/utils/variables';
@import '../../styles/utils/boxshadow';
@import '../../styles/utils/filters';
@import '../../styles/utils/mixin';
@import '../../styles/utils/responsive';
@import '../../styles/utils/textstyle';

@import '../../styles/presets/buttons';

.tutorial-kakao-channel-guide {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px 20px 70px;
  background-color: $color__lgrey_50;

  img,
  svg {
    -webkit-user-drag: none;
    -webkit-touch-callout: none;
  }

  &-title {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-bottom: 14px;

    // & > svg {
    //   min-width: fit-content;
    //   min-height: fit-content;
    // }

    &-text {
      margin: 0;
      @include font(16px, $color__default, 700, normal);
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;

    .content-item {
      display: flex;
      flex-direction: column;
      gap: 10px;

      &-tabletCustomInformationMessage {
        display: flex;
        flex-direction: column;
        gap: 4px;

        .paragraph {
          @include font(14px, #291257, 700, normal);

          &-ext {
            @include font(14px, $color__grey_66, 400, normal);
          }
        }
      }

      &-image {
        display: flex;
        justify-content: center;

        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
    }

    .content-horizontal-divider {
      height: 1px;
      background-color: $color__lgrey_100;
    }
  }
}
