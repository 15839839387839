@import '../../styles/utils/variables';
@import '../../styles/utils/boxshadow';
@import '../../styles/utils/filters';
@import '../../styles/utils/mixin';
@import '../../styles/utils/responsive';
@import '../../styles/utils/textstyle';

@import '../../styles/presets/buttons';

.kakao-channel-message-guide-field {
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: 14px;
  border: 1px solid $color__lgrey_100;
  border-radius: 4px;

  p {
    @include font(14px, $color__default, 500, normal);
    min-height: 1em;
  }
}
