@import '../../styles/utils/variables';
@import '../../styles/utils/boxshadow';
@import '../../styles/utils/filters';
@import '../../styles/utils/mixin';
@import '../../styles/utils/responsive';
@import '../../styles/utils/textstyle';

@import '../../styles/presets/buttons';

.custom-input-wrapper {
  display: flex;
  width: 100%;
  input {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 9px 12px;

    width: 100%;
    height: 40px;

    border: 1px solid $color__lgrey_300;
    border-radius: 4px;

    color: $color__black_1d;
    background: $color__white;
    outline: 0;
    caret-color: $color__p_purple;
    &:focus,
    &:active {
      border-width: 2px;
      border-color: $color__p_purple;
      box-sizing: border-box;
    }
    &:disabled {
      background: $color__lgrey_50;
      border: 1px solid $color__lgrey_300;
      color: $color__grey_600;
    }
    &::placeholder {
      color: $color__grey_600;
    }
    &[error] {
      border: 1px solid $color_error;
      & + span {
        color: $color_error;
        padding: 0 12px;
      }
      &:focus,
      &:active {
        border-width: 2px;
        box-sizing: border-box;
      }
    }
  }

  &.tutorial {
    &.disabled {
      background-color: $color__white;
      cursor: not-allowed;
    }

    input {
      height: 48px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      padding: 9px 14px;
      background-color: $color__lgrey_100;
      border: 1px solid $color__lgrey_100;
      border-radius: 4px;
      cursor: text;
      transition: border 100ms;

      &:focus {
        outline: 0;
        border: 1px solid $color__p_purple;
      }

      &::placeholder {
        @include font(14px, $color__grey_600, 400, normal);
      }

      &[error] {
        border: 1px solid $color_error;
        & + span {
          color: $color_error;
          padding: 0;
          display: flex;
          align-items: center;

          &::before {
            content: '';
            background-image: url(/assets/images/icons/ic_warning.svg);
            width: 16px;
            height: 16px;
          }
        }
        &:focus,
        &:active {
          border-width: 2px;
          box-sizing: border-box;
        }
      }
    }

    &-clear-btn {
      border: 0;
      background-color: inherit;
      line-height: 0;
      cursor: pointer;

      // & > svg {
      //   min-width: fit-content;
      //   min-height: fit-content;
      // }
    }
  }
}

textarea {
  resize: none;
  border: 1px solid $color__lgrey_300;
  border-radius: 4px;
  outline: 0;
  caret-color: $color__p_purple;
  padding: 9px 12px;

  &:focus,
  &:active {
    border-width: 2px;
    border-color: $color__p_purple;
    box-sizing: border-box;
  }
}
