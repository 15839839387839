@keyframes slideUp {
  from {
    bottom: -281px;
  }

  to {
    bottom: 0;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
