@import '../../styles/utils/variables';
@import '../../styles/utils/boxshadow';
@import '../../styles/utils/filters';
@import '../../styles/utils/mixin';
@import '../../styles/utils/responsive';
@import '../../styles/utils/textstyle';

@import '../../styles/presets/buttons';

.kakao-setting-item-image {
  width: 240px;
  height: 120px;
  background-color: #f9f9f9;
  // background-image: url(/assets/images/icons/kakao_about_mall_text_image_dummy.svg) ;
  // background-repeat: no-repeat;
  // background-position: right 8px bottom 6px;
  display: grid;
  padding: 12px 5px 4px 11px;
  grid-template-columns: 1fr auto; // span과 svg를 각각 하나의 열로 배치

  & > span {
    @include font__body_1;
    width: 157px;
    display: inline-block;
    word-wrap: break-word;
  }

  & > svg {
    width: 54px;
    height: 54px;
    justify-self: end; // 오른쪽으로 정렬
    align-self: end; // 아래로 정렬
  }
}
