@import '../../../../styles/utils/variables';
@import '../../../../styles/utils/boxshadow';
@import '../../../../styles/utils/filters';
@import '../../../../styles/utils/mixin';
@import '../../../../styles/utils/responsive';
@import '../../../../styles/utils/textstyle';

@import '../../../../styles/presets/buttons';

.reservation-check-wrapper {
  padding: 0 20px 24px 20px;
  background: $color__white;

  & > .check-contents {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 24px;
    border: 1px solid $color__grey_900;
    border-radius: 4px;
    & > .check-item-wrapper {
      display: flex;
      flex-direction: column;
      gap: 4px;
      & > .check-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid $color__lgrey_100;
        padding-bottom: 8px;

        &.error {
          border-bottom: 1px solid $color_error;
          & ~ .error-message {
            display: block;
          }
        }
        & > div {
          white-space: pre;
          overflow: hidden;
          text-overflow: ellipsis;
          width: auto;
          max-width: calc(100% - 50px);
          & > span {
            color: $color__grey_600;

            &.highlight {
              color: $color__default;
            }

            &::after {
              content: ' / ';
            }
            &:last-child {
              &::after {
                content: '';
              }
            }
          }
        }
      }
      & > .error-message {
        color: $color_error;
        display: none;
      }
    }
  }
}
