@import '../../styles/utils/variables';
@import '../../styles/utils/boxshadow';
@import '../../styles/utils/filters';
@import '../../styles/utils/mixin';
@import '../../styles/utils/responsive';
@import '../../styles/utils/textstyle';

@import '../../styles/presets/buttons';

.kakao-preview {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-bottom: 48px;

  margin-top: 106px;

  img {
    width: 100%;
    margin-bottom: -6.5px;
  }
  .kakao-banner-area {
    padding: 24px 10px 10px;
  }
  .kakao-list-area {
    padding-block: 30px;
    background-color: $color__lgrey_50;
    span {
      @include font__heading_4;
      padding-inline: 20px;

      &.kakao-dot {
        color: #f0cc00;
        padding-inline: 0px;
      }
    }
  }
}
