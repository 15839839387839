@import '../../styles/utils/variables';
@import '../../styles/utils/boxshadow';
@import '../../styles/utils/filters';
@import '../../styles/utils/mixin';
@import '../../styles/utils/responsive';
@import '../../styles/utils/textstyle';

@import '../../styles/presets/buttons';

.time-picker-container {
  .time-division-container {
    display: flex;
    width: 100%;
    gap: 12px;
    .division-text {
      color: $color__black_1d;
      background: $color__white;
      border: 1px solid $color__grey_900;
      min-width: 40px;
      min-height: 32px;
      width: 40px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100px;
      flex: 1;
      position: sticky;
      top: 16px;
    }
    .time-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 24px;
      .hour-division {
        display: flex;
        align-items: flex-start;
        gap: 12px;

        &:last-child {
          padding-bottom: 24px;
          margin-bottom: 24px;
          border-bottom: 1px solid $color__lgrey_100;
        }
        .hour-division-text {
          flex-basis: 40px;
          text-align: center;
          padding: 8px 0;
        }
        .minutue-division-contianer {
          display: flex;
          flex-basis: 100%;
          flex-wrap: wrap;
          gap: 8px;
          .minute-division-box {
            padding: 8px 8px;
            background-color: $color__white;
            border: 1px solid $color__lgrey_300;
            border-radius: 4px;
            flex-basis: 30%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            flex-grow: 1;
            color: $color__grey_600;
            &.selected {
              background-color: $color__p_purple_light;
              color: $color__p_purple;
              border: 1px solid $color__p_purple;
            }
            &.disabled {
              background: $color__white;
              color: $color__white;
              border: 1px solid $color__lgrey_100;
              cursor: default;
            }
            &:active {
              border-radius: 4px;
              border: 1px solid $color__grey_900;
              background: $color__lgrey_100;
              color: $color__grey_900;
            }
          }
        }
      }
    }

    &:last-child {
      .time-container {
        .hour-division:last-child {
          margin-bottom: 0;
          border: none;
        }
      }
    }
  }
}
