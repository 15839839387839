@import '../../styles/utils/variables';
@import '../../styles/utils/boxshadow';
@import '../../styles/utils/filters';
@import '../../styles/utils/mixin';
@import '../../styles/utils/responsive';
@import '../../styles/utils/textstyle';

@import '../../styles/presets/buttons';

.progress-bar {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 20px;

  .progress-bar-item {
    flex: 1;
    height: 8px;
    background-color: #f2f2f2;
    border-radius: 9px;

    &.fill {
      background-color: $color__p_green;
    }
  }
}
