@mixin responsive--mobile {
  @media (max-width: #{$breakpoint__mobile}) {
    @content;
  }
}

@mixin responsive--tablet {
  @media (min-width: #{$breakpoint__mobile}) and (max-width: #{$breakpoint__desktop - 1px}) {
    @content;
  }
}

@mixin responsive--desktop {
  @media (min-width: #{$breakpoint__desktop}) and (max-width: #{$breakpoint__xlg - 1px}) {
    @content;
  }
}
@mixin responsive--xlg {
  @media (min-width: #{$breakpoint__xlg}) {
    @content;
  }
}

//

@mixin clearFix() {
  &:after {
    content: '';
    display: block;
    clear: both;
  }
}

@mixin roundBox($radius: 6px, $shadow: $shadow-light) {
  border-radius: $radius;
  box-shadow: $shadow;
}

@mixin font($size, $color, $weight, $lineheight: 1.4) {
  font : {
    size: $size;
    weight: $weight;
  }
  color: $color;
  line-height: $lineheight;
  //letter-spacing: -0.5px;
}

@mixin positionCenter($position: absolute) {
  position: $position;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
