/* button default color */
button {
  background-color: $color__lgrey_50;
}

/* Mixins */
@mixin tblm-button-base {
  @include font__subtitle2_b;
  border: 0;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }
}

@mixin tblm-button-normal {
  @include tblm-button-base;
  line-height: 18px;
  height: 48px;
  padding: 0 16.5px;
}

@mixin tblm-button-small {
  @include tblm-button-base;
  height: 36px;
  padding: 8px 14px;
  font-size: 13px;
  border-radius: 40px;
}

@mixin tblm-button-color($maincolor: $color__p_purple, $subcolor: $color__white) {
  @include tblm-button-base;
  position: relative;
  background-color: $maincolor;
  color: $subcolor;

  &:hover {
    &::after {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba($color__white, 0.08);
    }
  }

  &:active {
    &::after {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      // background-color: rgba($color__white, 0.32);
    }
  }

  &:disabled {
    background-color: rgba($maincolor, 50%);
    color: rgba($subcolor, 50%);

    &::after {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba($color__white, 56%);
    }
  }
}

@mixin tblm-button-floating($bgcolor: $color__p_purple_dark, $color: $color__white) {
  @include tblm-button-normal;
  position: relative;
  border-radius: 50%;
  width: 64px;
  height: 64px;
  background-color: $bgcolor;
  color: $color;

  & > i {
    &::before,
    &::after {
      background-color: $color;
      width: 2px;
      height: 2px;
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 1px;
      content: '';
    }

    &::before {
      width: 20px;
    }

    &::after {
      height: 20px;
    }
  }

  &:hover {
    &::after {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba($color, 0.08);
    }
  }

  &:active {
    &::after {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba($color, 0.32);
    }
  }

  &:disabled {
    background-color: rgba($bgcolor, 50%);
    color: rgba($color, 50%);

    &::after {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba($color, 0.56);
    }
  }
}

@mixin tblm-button-gray {
  @include tblm-button-base;
  position: relative;

  background-color: $color__lgrey_50;
  // color: $color__black;

  &:hover {
    &::after {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba($color__default, 0.04);
    }
  }

  &:active {
    &::after {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba($color__default, 0.12);
    }
  }

  &:disabled {
    background-color: rgba($color__lgrey_50, 2%);
    color: rgba($color__default, 50%);

    &::after {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba($color__default, 0.2);
    }
  }
}

@mixin tblm-button-purple {
  @include tblm-button-base;
  position: relative;

  background-color: $color__p_purple;

  // &:hover {
  //   &::after {
  //     content: '';
  //     position: absolute;
  //     display: block;
  //     top: 0;
  //     left: 0;
  //     right: 0;
  //     bottom: 0;
  //     background-color: rgba($color__p_dpurple, 0.04);
  //   }
  // }

  &:active {
    background-color: $color__p_dpurple;
  }

  &:disabled {
    background-color: $color__p_purple_dimmed;
    color: rgba($color__default, 60%);
  }
}

@mixin tblm-button-white {
  @include tblm-button-base;
  position: relative;

  background-color: $color__white;
  border: 1px solid $color__lgrey_300;

  // &:hover {
  //   &::after {
  //     content: '';
  //     position: absolute;
  //     display: block;
  //     top: 0;
  //     left: 0;
  //     right: 0;
  //     bottom: 0;
  //     background-color: rgba($color__default, 0.04);
  //   }
  // }

  &:active {
    border: 1px solid $color__grey_600;
    color: $color__grey_600;
    background-color: $color__lgrey_100;
  }

  &:disabled {
    background-color: $color__white;
    color: rgba($color__default, 30%);
  }
}

@mixin tblm-button-outlined($color: $color__p_purple) {
  @include tblm-button-base;
  position: relative;
  background-color: $color__white;
  color: $color;
  box-shadow: 0 0 0 1px inset $color;

  &:hover {
    &::after {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba($color, 4%);
    }
  }

  &:active {
    background-color: rgba($color, 12%);
  }

  &:disabled {
    background-color: $color__white;
    color: rgba($color, 50%);

    &::after {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba($color, 20%);
    }
  }
}
