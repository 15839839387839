@import '../../styles/utils/variables';
@import '../../styles/utils/boxshadow';
@import '../../styles/utils/filters';
@import '../../styles/utils/mixin';
@import '../../styles/utils/responsive';
@import '../../styles/utils/textstyle';

@import '../../styles/presets/buttons';

.onoff-item {
  &-container {
    width: 100%;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    gap: 14px;
    display: inline-flex;
  }

  &-context {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 4px;
    display: inline-flex;
  }

  &-title-container {
    align-self: stretch;
    gap: 4px;
    display: inline-flex;
  }

  &-icon {
    width: 24px;
    height: 24px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  &-title {
    @include font__heading_6;
    word-wrap: break-word;
  }

  &-tabletCustomInformationMessage-container {
    align-self: stretch;
    padding-left: 28px;
    display: inline-flex;
  }

  &-tabletCustomInformationMessage {
    @include font__body_6;
    color: $color__grey_600;
    word-wrap: break-word;
  }
}
