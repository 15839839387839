@import '../../../../styles/utils/variables';
@import '../../../../styles/utils/boxshadow';
@import '../../../../styles/utils/filters';
@import '../../../../styles/utils/mixin';
@import '../../../../styles/utils/responsive';
@import '../../../../styles/utils/textstyle';

@import '../../../../styles/presets/buttons';

.user-info-menu-modal-wrapper {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  & > .title {
    padding: 0 20px;
    display: flex;
    justify-content: flex-start;
  }
  & > .content {
    display: flex;
    flex-direction: column;
  }
}
