@import '../../styles/utils/variables';
@import '../../styles/utils/boxshadow';
@import '../../styles/utils/filters';
@import '../../styles/utils/mixin';
@import '../../styles/utils/responsive';
@import '../../styles/utils/textstyle';

@import '../../styles/presets/buttons';

.tutorial-kakao-channel-content-item-wrapper {
  display: flex;
  flex-direction: column;

  &.padding {
    padding: 0 20px;
  }

  .tutorial-kakao-channel-content-item {
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 16px 0;

    .ellipse-box {
      padding: 3px;

      .ellipse {
        width: 6px;
        height: 6px;
        background-color: $color__p_green;
        border-radius: 99px;
      }
    }

    .paragraph {
      @include font(14px, $color__default, 500, normal);
    }
  }

  .tutorial-kakao-channel-content-warning {
    display: flex;
    // align-items: center;
    gap: 2px;

    svg {
      margin-top: 1px;
    }

    .paragraph {
      @include font__body_7;
      color: $color__s_red;
    }
  }

  .content-horizontal-divider {
    height: 1px;
    background-color: $color__lgrey_100;
  }
}
