@import '../../styles/utils/variables';
@import '../../styles/utils/boxshadow';
@import '../../styles/utils/filters';
@import '../../styles/utils/mixin';
@import '../../styles/utils/responsive';
@import '../../styles/utils/textstyle';

@import '../../styles/presets/buttons';

.header-title-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3;
  background: $color__white;

  &:not(.no-border) {
    border-bottom: 1px solid $color__lgrey_50;
  }

  .header-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;

    .dummy {
      width: 40px;
      height: 30px;
    }

    .title {
      margin: 0;
    }

    .close {
      margin-left: 10px;
      height: 30px;

      svg {
        width: 30px;
        height: 30px;

        path {
          fill: $color__default;
        }
      }
    }
  }
}
