@import '../../../styles/utils/variables';
@import '../../../styles/utils/boxshadow';
@import '../../../styles/utils/filters';
@import '../../../styles/utils/mixin';
@import '../../../styles/utils/responsive';
@import '../../../styles/utils/textstyle';

@import '../../../styles/presets/buttons';

.enter-setting {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;

  * {
    cursor: pointer;
  }

  &-btn {
    display: flex;
    align-items: center;
    padding: 8px 12px 8px 8px;
    height: 36px;

    border: 0;
    border-radius: 48px;
    background-color: $color__lgrey_100;
    font-size: 14px;
    font-weight: 500;
    color: $color__default;

    &:active:hover {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.12) 0%, rgba(0, 0, 0, 0.12) 100%), $color__lgrey_100;
    }

    svg {
      margin-right: 1px;
    }
  }
}
