@import '../../../styles/utils/variables';
@import '../../../styles/utils/boxshadow';
@import '../../../styles/utils/filters';
@import '../../../styles/utils/mixin';
@import '../../../styles/utils/responsive';
@import '../../../styles/utils/textstyle';

@import '../../../styles/presets/buttons';

.table-edit-wrapper {
  .table-group-container {
    margin: 32px 0 20px;
    padding: 0 20px;
    .table-group-header {
      position: sticky;
      position: -webkit-sticky; /* Safari */
      top: 55px;
      margin-bottom: 8px;
      padding: 13px 12px;
      display: flex;
      align-items: center;
      // border: 1px solid $color__lgrey_300;
      border-radius: 4px;
      background-color: $color__white;
      .table-group-name {
        width: fit-content;
        max-width: 100%;
        display: block;
        overflow: hidden;
        flex: 1;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .icon-trash {
        margin-left: 8px;
        & > path {
          width: 16px;
          height: 16px;
        }
      }
      &[stuck] {
        & > svg {
          margin-left: auto;
        }
        border-radius: 0;
        z-index: 2;
        margin: 0 -20px;
        padding: 14px 20px;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
        border-left: 0;
        border-right: 0;
      }
    }
    .table-list-wrapper {
      margin-top: 12px;
      padding: 20px;
      background-color: $color__white;
      border-radius: 4px;
      .table-add-button {
        display: flex;
        height: 36px;
        align-items: center;
        justify-content: center;
        border-radius: 40px;
        width: 100%;
        font-weight: 500;
      }
      .table-container + .table-add-button {
        margin-top: 20px;
      }
    }
  }
}
