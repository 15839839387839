@import '../../styles/utils/variables';
@import '../../styles/utils/boxshadow';
@import '../../styles/utils/filters';
@import '../../styles/utils/mixin';
@import '../../styles/utils/responsive';
@import '../../styles/utils/textstyle';

@import '../../styles/presets/buttons';

.kakao-text-area {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;

  &-field {
    width: 100%;
    height: 150px;
    resize: none;
    padding: 14px;
    box-sizing: border-box;
    border: 1px solid $color__lgrey_100;
    border-radius: 4px;
    background-color: $color__lgrey_100;
    transition: border 100ms;
    @include font(14px, $color__default, 500, normal);

    &:focus {
      border: 1px solid $color__p_purple;
      caret-color: $color__p_purple;
      padding-right: 45px;
    }

    &::placeholder {
      @include font(14px, $color__grey_600, 400, normal);
    }
  }

  & > .kakao-input-clear-btn {
    position: absolute;
    top: 9px;
    right: 14px;
  }

  &-counter {
    @include font(14px, $color__lgrey_300, 400, normal);
  }

  .kakao-text-area-info {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  .kakao-text-area-warning {
    display: flex;
    align-items: center;
    @include font__body_7;
    color: $color__s_red;
  }
}
