.reservation_list_page_blank {
  width: 100%;
  height: 100%;
}

// .main-container-desktop:has(.reservation_list_page) {
//   overflow-y: hidden;
// }

.reservation_list_page {
  background-color: $color__white;
  width: 100%;
  min-height: calc(100% - 106px); // margin top size
  display: flex;
  flex-direction: column;
  margin-top: 106px;

  .reservation_top {
    z-index: 1;
    position: sticky;
    top: 106px;
    padding-top: 16px;
    width: 100%;
    background-color: $color__white;
    flex-shrink: 0;
    flex-grow: 0;

    & > .schedule {
      padding: 0 20px;
      height: 67px;
      border-bottom: 1px solid $color__lgrey_50;
      background-color: $color__white;
      cursor: pointer;
    }

    & > .filter-menu {
      padding: 0 20px;
      height: 49px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid $color__lgrey_50;
      background-color: $color__white;

      & > .time-div {
        display: flex;
        align-items: center;
        gap: 24px;
        cursor: pointer;
        overflow-y: scroll;
        padding-right: 28px;

        & > label {
          display: flex;
          align-items: center;

          & > i {
            margin-right: 4px;
            transform: none;
          }
          & > input ~ span {
            @include font__small;
            color: $color__black_1d;
            word-break: keep-all;
          }
          & > input:not(:checked) ~ span {
            color: $color__grey_66;
          }
        }
      }

      .time-control-panel {
        display: flex;
        align-items: center;
        position: relative;

        &::before {
          content: '';
          position: absolute;
          left: -28px;
          right: 0;
          top: 0;
          bottom: 0;
          background: linear-gradient(270deg, #fff 82.76%, rgba(255, 255, 255, 0) 101.72%);
        }

        .calendar-icon-wrap {
          display: flex;
          align-items: center;
          padding: 8px;
          padding-left: 0;
          z-index: 1;

          & > svg {
            width: 22px;
            height: 22px;
            cursor: pointer;
          }
        }

        .today-btn-wrap {
          width: max-content;
          display: flex;
          align-items: center;
          padding: 8px;
          padding-right: 0;
          z-index: 1;

          button.today {
            @include font__s_small_sb;
            padding: 0 6px;
            height: 24px;
            border: 1.66px solid $color__grey_900;
            border-radius: 4px;
            background-color: $color__white;
            color: $color__black;
            cursor: pointer;

            &.active {
              background: $color__p_purple;
              color: $color__white;
              border: 1px solid $color__p_purple;
            }
          }
        }
      }
    }
  }

  .reservation_list {
    padding: 12px 20px 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    flex: 1;

    & > .indicator {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
      // width: 335px;
      width: 100%;
      text-align: left;

      .reservation-counter {
        @include font__body_6;
      }

      & > button.toggle_filter {
        margin-left: auto;
        padding: 8px 12px;
        border: 0;
        border-radius: 48px;
        background: $color__lgrey_100;
        cursor: pointer;

        &:active:hover {
          background: linear-gradient(0deg, rgba(0, 0, 0, 0.12) 0%, rgba(0, 0, 0, 0.12) 100%), $color__lgrey_100;
        }

        svg {
          vertical-align: middle;
        }
      }
    }

    & > .card {
      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }
    & > .empty-data-note {
      margin: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      @include font(15px, #7e7b83, 400, 22px);
      color: $color__lgrey_300;
      & > svg {
        margin-bottom: 16px;
      }
    }
  }

  & > .tblm-btn-float {
    position: fixed;
    right: 16px;
    bottom: 32px;
    padding: 16px;
  }
}

button.bottom_modal__close {
  position: absolute;
  top: 12px;
  right: 12px;
  width: 28px;
  height: 28px;
  display: block;
  border: 0;
  border-radius: 14px;
  background-image: url('/assets/images/icons/Close_BoottoMenu.svg');
  background-size: 100%;
  cursor: pointer;
}

.send_cancel_alarm_wrap {
  display: flex;
  margin-top: 16px;
  justify-content: center;
  label {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    @include font__body_5;
    i {
      margin-left: 8px;
      margin-top: 2px;
    }
  }
}
