.month-calendar-wrapper {
  background: $color__white;
  display: flex;
  flex-direction: column;
  gap: 24px;
  &::before {
    position: absolute;
    content: '';
    top: -1px;
    width: 100%;
    height: 2px;
    background: $color__white;
  }
}
.timepicker-wrapper {
  background: $color__white;
  padding: 0 20px;
}

.table-setting-wrapper {
  position: relative;
  // height: 100%;
  width: 100%;
  & > nav.is-fixed {
    & + .setting-area {
      // height: auto;
    }
  }
  & > .setting-area {
    // height: calc(100% - 89px - 136px);
    margin-bottom: 40px;
  }
}
.select-persons-wrapper {
  background: $color__white;
  padding-bottom: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .font__heading_5 {
    padding: 0 20px;
  }

  .slide-x {
    padding: 0 20px;
  }
  .tblm-chip-filter-num {
    min-width: 40px;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.select-table-wrapper {
  border-top: 1px solid $color__lgrey_50;
  background: $color__white;
  & > .table-title {
    padding: 24px 20px 8px 20px;
  }
  & > .table-content {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 24px 16px;
  }
}
.user-search-wrapper {
  width: 100%;
  // height: calc(100% - 137px);
  // height: calc(100% - 136px - 89px);
  display: flex;
  padding: 0 20px;
  flex-direction: column;
  background: $color__white;
  gap: 24px;
  overflow: hidden;
  // min-height: calc(100% - 20px);
  button.search-button {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
  }
}
.parties-wrapper {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  & > span {
    &::after {
      content: ', ';
      padding-right: 5px;
    }
    &:last-child {
      &::after {
        content: '';
      }
    }
  }
}
.reservation-detail-wrapper {
  padding: 32px 20px 16px 20px;
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: $color__lgrey_50;

  & .guide-message {
    margin-left: 4px;
    @include font__body_5;
    color: $color__s_red;
  }

  & > .detail-content {
    padding: 20px 16px 16px;
    gap: 8px;
    display: flex;
    flex-direction: column;
    background: $color__white;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.04);
    border-radius: 8px;

    .custom-check-wrapper {
      padding: 0;
    }

    & > .detail-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      & > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 12px 0;

        & > div:first-child {
          max-width: 104px;
          width: 100%;
          word-break: keep-all;
          &.required {
            &::after {
              content: '*';
              line-height: 30px;
              padding-left: 6px;
              color: $color_error;
            }
          }
        }
        & > div:last-child {
          width: 100%;
        }
      }
    }
  }

  & .menuinfomation-content {
    padding-bottom: 20px;
    gap: 20px;
  }
}
