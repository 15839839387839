@import '../../styles/utils/variables';
@import '../../styles/utils/boxshadow';
@import '../../styles/utils/filters';
@import '../../styles/utils/mixin';
@import '../../styles/utils/responsive';
@import '../../styles/utils/textstyle';

@import '../../styles/presets/buttons';

.kakao-channel-profile-image {
  width: 160px;
  height: 160px;
  border-radius: 60px;

  &.text-profile {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffe500;

    > span {
      font-weight: 700;
      font-size: 28px;
      line-height: normal;
      text-align: center;

      &.small-oneline {
        font-size: 24px;
        line-height: 31.2px;
      }
      &.twoline {
        font-size: 24px;
        line-height: 31.2px;
        margin: 0 13px;
      }
      &.small-twoline {
        font-size: 22px;
        line-height: 28.6px;
        margin: 0 9px;
      }
      &.small-threeline {
        font-size: 22px;
        line-height: 28.6px;
        margin: 0 9px;
      }
    }
  }
}
