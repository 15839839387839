@import '../../../styles/utils/variables';
@import '../../../styles/utils/boxshadow';
@import '../../../styles/utils/filters';
@import '../../../styles/utils/mixin';
@import '../../../styles/utils/responsive';
@import '../../../styles/utils/textstyle';

@import '../../../styles/presets/buttons';

.reservation-detail-body {
  background-color: $color__lgrey_50;
  padding-top: 108px;
  .footer-sub-wrapper button.tblm-button-normal {
    padding: 0;
  }
  .content-wrapper {
    &.cancel {
      margin-top: 64px;
      button {
        display: none;
      }
      input {
        border: 0;
        outline: none;
        background-color: white;
        color: black;
        padding: 0;
      }
      .essential {
        display: none;
      }
    }

    > :last-child {
      margin-bottom: 20px;
    }
    .menuinfomation-content {
      gap: 20px;
      padding-bottom: 20px;
    }
  }
  .isCancelledReservation {
    height: 48px;
    width: 100%;
    padding: 0 20px;
    background-color: $color_error;
    display: flex;
    align-items: center;
    @include font__subtitle_m;
    color: $color__white;
    position: fixed;
    top: 104px;
    z-index: 2;
  }
}
