@import '../../styles/utils/variables';
@import '../../styles/utils/boxshadow';
@import '../../styles/utils/filters';
@import '../../styles/utils/mixin';
@import '../../styles/utils/responsive';
@import '../../styles/utils/textstyle';

@import '../../styles/presets/buttons';

.reservation_nav {
  margin: 0;
  padding: 8px 0 0;
  background-color: $color__white;
  min-height: 100vh;

  &__list {
    @include font(inherit, $color__default, 700, 19px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 72px;
    margin: 0 16px;
    padding: 0 8px;
    border-bottom: 1px solid $color__lgrey_50;
    letter-spacing: -0.3px;

    & > svg > path {
      fill: $color__grey_600;
    }
  }
  &_tabletCustomInformationMessage {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }
  &_using_state {
    color: #606874;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px; /* 137.5% */
  }
  &_using {
    display: flex;
    align-items: center;
    color: $color__p_purple;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  }
  &_unusing {
    display: flex;
    align-items: center;
    color: #606874;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  }
}
