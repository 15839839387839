@import '../../styles/utils/variables';
@import '../../styles/utils/boxshadow';
@import '../../styles/utils/filters';
@import '../../styles/utils/mixin';
@import '../../styles/utils/responsive';
@import '../../styles/utils/textstyle';

@import '../../styles/presets/buttons';

.kakao-setting-noti-banner-wrapper {
  width: 100%;
  padding: 0 20px;
  background-color: $color__white;

  .kakao-setting-noti-banner {
    display: flex;
    gap: 4px;
    padding: 10px;
    border-radius: 4px;
    background-color: #291257;
    @include font(14px, $color__white, 400, normal);

    // svg {
    //   min-width: fit-content;
    //   min-height: fit-content;
    // }
  }
}
