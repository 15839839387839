@import '../../styles/utils/variables';
@import '../../styles/utils/boxshadow';
@import '../../styles/utils/filters';
@import '../../styles/utils/mixin';
@import '../../styles/utils/responsive';
@import '../../styles/utils/textstyle';

@import '../../styles/presets/buttons';

// ListFilter styles
.list_filter {
  // max-width: 375px!important;

  & > .list__filter_title {
    @include font__heading_6;
    margin-bottom: 24px;
  }
  & > .list__filter_content {
    color: $color__black;
    &.list__filter_group_list {
      // height: calc(100vh - 408px - 100vw + 385px);
      // @media (max-width: 290px) {
      //   height: calc(100vh - 554px - 100vw + 385px);
      // }
      min-height: 100px;
      // max-height: 380px;
      overflow: overlay;
      overflow: auto;
      &.topped {
        margin-top: 52px;
      }
      & > li {
        border-top: 1px solid $color__lgrey_100;
        &:first-child {
          border-top: 0;
        }
        & > label {
          display: flex;
          padding: 16px 4px;
          justify-content: space-between;
          align-items: center;
          flex-direction: row-reverse;
          cursor: pointer;
          & > i {
            margin-left: auto;
          }
        }
      }
    }
    &.list__filter_status_list {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      gap: 16px;

      & > label {
        // min-width: 73px;
        // flex-grow: 0;
        // flex-shrink: 0;
        cursor: pointer;
        & > input {
          display: none;
        }
      }
    }
  }
  & > hr {
    margin: 20px -20px;
    width: calc(100% + 40px);
    height: 1px;
    border: 0;
    background-color: $color__lgrey_50;
  }
  & > .list__filter_apply {
    position: sticky;
    bottom: 0;
    padding: 20px 0;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
    button {
      @include font__subtitle1_b;
      width: 100%;
      height: 48px;
      display: flex;
      align-items: center;
      flex-grow: 0;
      flex-shrink: 0;
      justify-content: center;
      border: 0;
      border-radius: 6px;
      background-color: $color__p_purple;
      color: $color__white;
      cursor: pointer;
    }
  }
}
