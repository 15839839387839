@import '../../../styles/utils/variables';
@import '../../../styles/utils/boxshadow';
@import '../../../styles/utils/filters';
@import '../../../styles/utils/mixin';
@import '../../../styles/utils/responsive';
@import '../../../styles/utils/textstyle';

@import '../../../styles/presets/buttons';

.table-view-container {	
  .table-group-container {
    padding-top: 32px;

    .tblm-chip-filter-menu {
      text-overflow: ellipsis;
      max-width: 100%;
      width: fit-content;
      white-space: nowrap;
      overflow: hidden;
      display: block;
    }

    .table-wrapper {
      border-radius: 4px;
      background-color: $color__white;
      margin-top: 12px;

      .table-container {
        border-bottom: 1px solid $color__lgrey_50;

        &:last-child {
          border-bottom: 0;
        }
      }
    }
  }
}
