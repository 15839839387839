@import '../../styles/utils/variables';
@import '../../styles/utils/boxshadow';
@import '../../styles/utils/filters';
@import '../../styles/utils/mixin';
@import '../../styles/utils/responsive';
@import '../../styles/utils/textstyle';

@import '../../styles/presets/buttons';

.tutorial-kakao-channel-name {
  &-warning {
    &-wrapper {
      display: flex;
      gap: 2px;

      // & > svg {
      //   min-width: fit-content;
      //   min-height: fit-content;
      // }
    }

    &-text {
      @include font__s_small;
      color: #ff6161;
      line-height: 1.3;
    }
  }
}
