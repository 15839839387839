/* Mixins */
@mixin font__title_32 {
  @include font(32px, $color__default, 400, 40px);
}
@mixin font__title_32_m {
  @include font(32px, $color__default, 500, 40px);
}
@mixin font__title_32_sb {
  @include font(32px, $color__default, 600, 40px);
}
@mixin font__title_28_m {
  @include font(28px, $color__default, 500, 41px);
}
@mixin font__title_24_b {
  @include font(24px, $color__default, 700, 31px);
}
@mixin font__title_20 {
  @include font(20px, $color__default, 400, 28px);
}
@mixin font__title_20_m {
  @include font(20px, $color__default, 500, 28px);
}
@mixin font__title_20_sb {
  @include font(20px, $color__default, 600, 28px);
}
@mixin font__title_20_b {
  @include font(20px, $color__default, 700, 28px);
}
@mixin font__title_19 {
  @include font(19px, $color__default, 400, 24px);
}
@mixin font__title_19_m {
  @include font(19px, $color__default, 500, 24px);
}
@mixin font__title_19_sb {
  @include font(19px, $color__default, 600, 24px);
}
@mixin font__title_19_b {
  @include font(19px, $color__default, 700, 24px);
}
@mixin font__header_title {
  @include font(18px, $color__default, 700, 22px);
}
@mixin font__title_16_b {
  @include font(16px, $color__default, 700, 24px);
}
@mixin font__title_16 {
  @include font(16px, $color__default, 400, 24px);
}
@mixin font__subtitle {
  @include font(17px, $color__default, 400, 20px);
}
@mixin font__subtitle_m {
  @include font(17px, $color__default, 500, 20px);
}
@mixin font__subtitle_sb {
  @include font(17px, $color__default, 600, 20px);
}
@mixin font__subtitle_b {
  @include font(17px, $color__default, 700, 20px);
}
@mixin font__subtitle1 {
  @include font(15px, $color__default, 400, 18px);
}
@mixin font__subtitle1_m {
  @include font(15px, $color__default, 500, 18px);
}
@mixin font__subtitle1_sb {
  @include font(15px, $color__default, 600, 18px);
}
@mixin font__subtitle1_b {
  @include font(15px, $color__default, 700, 18px);
}
@mixin font__subtitle2_b {
  @include font(14px, $color__default, 700, 18px);
}
@mixin font__subtitle2_sb {
  @include font(14px, $color__default, 600, 18px);
}
@mixin font__subtitle2_m {
  @include font(14px, $color__default, 500, 18px);
}
@mixin font__body_md {
  @include font(14px, $color__default, 400, 20px);
}
@mixin font__body_md_m {
  @include font(14px, $color__default, 500, 20px);
}
@mixin font__body_md_sb {
  @include font(14px, $color__default, 600, 20px);
}
@mixin font__body_sm {
  @include font(15px, $color__default, 400, 22px);
}
@mixin font__body_sm_m {
  @include font(15px, $color__default, 500, 22px);
}
@mixin font__body_sm_sb {
  @include font(15px, $color__default, 600, 22px);
}
@mixin font_f_small {
  @include font(14px, $color__grey_600, 400, 18px);
}
@mixin font_f_small_m {
  @include font(14px, $color__grey_600, 500, 18px);
}
@mixin font_f_small_sb {
  @include font(14px, $color__grey_600, 600, 18px);
}
@mixin font__small {
  @include font(13px, $color__default, 400, 16px);
}
@mixin font__small_m {
  @include font(13px, $color__default, 500, 16px);
}
@mixin font__small_sb {
  @include font(13px, $color__default, 600, 16px);
}
@mixin font__s_small {
  @include font(12px, $color__default, 400, 14px);
}
@mixin font__s_small_m {
  @include font(12px, $color__default, 500, 14px);
}
@mixin font__s_small_sb {
  @include font(12px, $color__default, 600, 14px);
}
@mixin font__s_small_b {
  @include font(12px, $color__default, 700, 14px);
}
@mixin font__ss_small {
  @include font(11px, $color__default, 400, 12px);
}
@mixin font__ss_small_m {
  @include font(11px, $color__default, 500, 12px);
}
@mixin font__ss_small_sb {
  @include font(11px, $color__default, 600, 12px);
}

@mixin font__heading_1 {
  @include font(28px, $color__default, 700, normal);
}
@mixin font__heading_2 {
  @include font(26px, $color__default, 700, normal);
}
@mixin font__heading_3 {
  @include font(20px, $color__default, 700, normal);
}
@mixin font__heading_4 {
  @include font(18px, $color__default, 700, normal);
}
@mixin font__heading_5 {
  @include font(16px, $color__default, 700, normal);
}
@mixin font__heading_6 {
  @include font(16px, $color__default, 500, normal);
}

@mixin font__body_1 {
  @include font(16px, $color__default, 700, normal);
}
@mixin font__body_2 {
  @include font(16px, $color__default, 500, normal);
}
@mixin font__body_3 {
  @include font(16px, $color__default, 400, normal);
}
@mixin font__body_4 {
  @include font(14px, $color__default, 700, normal);
}
@mixin font__body_5 {
  @include font(14px, $color__default, 500, normal);
}
@mixin font__body_6 {
  @include font(14px, $color__default, 400, normal);
}
@mixin font__body_7 {
  @include font(12px, $color__default, 400, normal);
}
