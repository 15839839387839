@import '../../styles/utils/variables';
@import '../../styles/utils/boxshadow';
@import '../../styles/utils/filters';
@import '../../styles/utils/mixin';
@import '../../styles/utils/responsive';
@import '../../styles/utils/textstyle';

@import '../../styles/presets/buttons';

@import './docs/AgreementDoc.scss';
// ListFilter styles

.agreement {
  padding: 20px 20px 20px 20px;
  overflow: unset !important;
  // max-width: 414px!important;
  // max-height: 100% !important;
  & > .close {
    min-height: 24px;
    padding: 12px 0 !important;
  }
  .agreement_modal_title {
    @include font__header_title;
    margin-bottom: 10px;
    color: $color__black;
    /* max-height: 40px; */
    height: 30px;
    white-space: pre;
    text-overflow: ellipsis;
    width: 100%;
    overflow-x: hidden;
    flex-shrink: 0;
    display: flex;
    justify-content: space-between;
  }
  .agreement_modal_title_span {
    margin-top: 5px;
  }
  .agreement_modal_note {
    @include font(14px, $color__grey_66, 400, normal);
    margin: 20px 0;
  }
  .agreement_modal_content {
    height: auto;
    overflow: auto;
    display: flex;
    flex-direction: column;
  }
  .agreement_modal_body {
    height: calc(100vh);
    padding-bottom: 20px;
    flex: 1;
    display: flex;
    flex-direction: column;
    label {
      display: flex;
      align-items: center;
      & > input {
        display: none;
      }
      & > i {
        margin-right: 10px;
        background-image: url('/assets/images/icons/Check_Filter_Enabled_b.svg');
        width: 20px;
        height: 20px;
        flex-shrink: 0;
      }
      & > input:checked + i {
        background-image: url('/assets/images/icons/Check_Filter_Selected_b.svg');
      }
    }
    & > .agreement_modal_subtitle {
      @include font__title_16_b;
      // height: 56px;
      // line-height: 23px !important;
    }
    & > .allCheck {
      @include font__body_md_sb;
      width: 100%;
      height: 57px;
      display: flex;
      align-self: center;
      border-bottom: 1px solid $color__grey_66;
      flex-shrink: 0;
      flex-grow: 0;
      padding: 16px 4px;

      & .allcheck_change_color {
        color: $color__p_purple;
      }
    }
    & > .agreement_list {
      margin: 0;
      padding: 0;
      max-height: 476px;
      overflow: overlay;
      overflow: auto;
      padding-bottom: 54px;
      height: calc(100vh - 160px);
      & > li {
        @include font__body_md_m;
        display: flex;
        align-items: center;
        padding: 16px 4px;
        line-height: 24px;

        @media (max-width: 359px) {
          font-size: 15px;
          line-height: 18px;
        }

        &:not(&:last-of-type) {
          border-bottom: 1px solid $color__lgrey_100;
        }

        .agreement_title {
          & > span {
            margin-right: 4px;
          }
          & > span.essential {
            color: $color__p_purple;
          }
        }
        & > svg {
          margin-left: auto;
          flex-shrink: 0;
        }
      }
    }
  }

  footer.agreement-btn-wrapper {
    max-width: 100vw;
    margin: 0 -20px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
    position: fixed;
    bottom: 0px;
    width: 100%;
    height: 88px;
    button.list__filter_apply {
      @include font__subtitle2_sb;
      margin-top: 20px;
      width: calc(100% - 40px);
      margin-inline: 20px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 0;
      border-radius: 6px;
      background-color: $color__p_purple;
      color: $color__white;
      cursor: pointer;
      flex-shrink: 0;
      flex-grow: 0;
      &:disabled {
        background-color: $color__p_purple_dimmed;
        color: $color__white;
      }
    }
  }
}
