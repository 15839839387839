@import '../../../../styles/utils/variables';
@import '../../../../styles/utils/boxshadow';
@import '../../../../styles/utils/filters';
@import '../../../../styles/utils/mixin';
@import '../../../../styles/utils/responsive';
@import '../../../../styles/utils/textstyle';

@import '../../../../styles/presets/buttons';

.reservation-service-content {
  display: flex;
  flex-direction: column;
  padding: 30px 20px;
  background-color: inherit;

  &-title {
    display: flex;
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 700;

    .text {
      margin: unset;
      color: $color__default;
    }

    .mark {
      color: #312ece;
    }
  }

  &-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    padding: 10px 20px;
    border-radius: 8px;
    border: 1px solid $color__lgrey_100;
    background-color: $color__white;
    box-shadow: 0 3px 14px 0 rgba(200, 200, 200, 0.2);

    &-card {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &-info {
        display: flex;
        flex-direction: column;
        gap: 4px;
        margin-right: 8px;
      }

      &-title {
        color: $color__default;
        font-size: 14px;
        font-weight: 500;
        margin: unset;
      }

      &-tabletCustomInformationMessage {
        color: $color__grey_600;
        white-space: break-spaces;
      }

      // &-image {
      //   min-width: fit-content;
      //   min-height: fit-content;
      // }

      &-divider {
        all: unset;
        width: 100%;
        border-bottom: 1px dashed $color__lgrey_100;
      }
    }
  }
}
