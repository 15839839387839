/* Presets */
button.tblm-button-normal {
  @include tblm-button-normal;
  @include font__subtitle1_b;
  @include tblm-button-white;
}
button.tblm-button-small {
  @include tblm-button-small;
  @include font__body_5;
}
button.tblm-btn-primary {
  @include font__subtitle1_b;
  @include tblm-button-purple;
  color: $color__white !important;
}
button.tblm-btn-float {
  @include tblm-button-floating;
}
button.tblm-btn-gray {
  @include font__subtitle1_b;
  @include tblm-button-gray;
}
button.tblm-btn-white {
  @include font__subtitle1_b;
  @include tblm-button-white;
}
button.tblm-btn-outlined {
  @include tblm-button-outlined($color__p_purple);
}
button.tblm-btn-paleblue {
  @include font__body_5;
  color: $color__p_purple;
  border-radius: 40px;
  background: $color__p_purple_light;
  color: $color__p_purple !important;
  border: none;

  &:active {
    background-color: #ded0ff;
  }

  &:disabled {
    opacity: 0.5;
  }
}

button.tblm-btn-applegreen {
  @include font__body_5;
  color: #3DCF94; // 텍스트 색상을 녹색으로 변경
  border-radius: 40px;
  background: #DBF8EC; // 배경색은 애플그린으로 유지
  border: none;

  &:active {
    background-color: #ded0ff; // 활성화 상태 배경색
  }

  &:disabled {
    opacity: 0.5;
  }
}


/* <예약 받는 시간> 버튼 style */
.reservation-time-tag-wrapper {
  display: flex;
  width: 100%;
  gap: 8px;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;

  & > .reservation-time-tag {
    @include font__body_sm;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px 6px;
    // width: 23%;
    width: calc(25% - 6px);
    background: $color__white;
    border: 1px solid $color__lgrey_100;
    color: $color__black;
    border-radius: 4px;
    letter-spacing: 1px;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }
}
