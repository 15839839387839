@import '../../styles/utils/variables';
@import '../../styles/utils/boxshadow';
@import '../../styles/utils/filters';
@import '../../styles/utils/mixin';
@import '../../styles/utils/responsive';
@import '../../styles/utils/textstyle';

@import '../../styles/presets/buttons';

.picker-body {
  .picker-title {
    font-weight: 600;
    font-size: 17px;
    line-height: 20px;
    color: $color__black;
    margin-bottom: 20px;
  }

  .picker-container {
    height: 164px;
    position: relative;
    // margin-bottom: 20px;
    .selected-background {
      position: absolute;
      background-color: $color__lgrey_300;
      top: calc(50% - 20px);
      left: 0;
      width: 100%;
      height: 40px;
      z-index: 1;
    }

    .picker-roller-container {
      display: flex;
      justify-content: center;
      height: 100%;
      width: 100%;

      .picker-roller {
        overflow-y: scroll;
        height: 100%;
        scroll-snap-type: y mandatory;
        scroll-behavior: smooth;
        z-index: 2;

        &.year-roller {
          margin-right: 16px;
        }
      }

      .picker-item {
        color: $color__grey_600;
        font-weight: 600;
        font-size: 15px;
        line-height: 18px;
        text-align: center;
        margin-bottom: 16px;
        scroll-snap-align: center;
        overflow: hidden;
        height: 18px;
        text-overflow: ellipsis;
        white-space: nowrap;
        &.selected {
          font-weight: 600;
          font-size: 20px;
          line-height: 28px;
          height: 28px;
          color: $color__p_purple;
        }
      }

      .picker-item-placeholder {
        height: 34px;
        width: 100%;
        scroll-snap-align: center;
      }
    }
  }
}

.picker-footer {
  padding: 20px 0;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
  .set-button {
    width: 100%;
  }
}
