@import '../../styles/utils/variables';
@import '../../styles/utils/boxshadow';
@import '../../styles/utils/filters';
@import '../../styles/utils/mixin';
@import '../../styles/utils/responsive';
@import '../../styles/utils/textstyle';

@import '../../styles/presets/buttons';

.table-edit-wrapper {
  background-color: $color__lgrey_50;
  padding-top: 1px;
  min-height: calc(100% - 60px);
  
  .table-group-add-button-wrapper {
    background-color: $color__white;
  }

  .button-container {
    padding:16px 20px 32px; 
  }

  .addTableGroup {
    @include font__body_4;
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $color__lgrey_300;
    border-radius: 6px;
    background-color: $color__white;
    z-index: 6;
    color: $color__black;
    & > svg {
      margin-right: 4px;
    }
  }
}

.add-group-modal-wrapper {
  margin-bottom: 20px;
  padding: 20px 0;
  .add-group-modal-header {
    padding-top: 20px;
    margin-bottom: 24px;
    line-height: 30px;
    color: $color__default;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 1px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.make-new-table-header {
  padding: 0 20px;
}
.new-table-info-wrapper {
  margin-top: 20px;
  padding: 20px;
  // margin-bottom: 20px;
  background-color: $color__lgrey_100;

  .table-name-input {
    margin-bottom: 16px;
  }

  .people-selector {
    margin: 0 16px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .people-icon-box {
      display: flex;
      align-items: center;

      .sign-icon {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid $color__lgrey_300;
        border-radius: 2px;
        background-color: $color__white;
      }
      .now-people {
        width: 56px;
        text-align: center;
      }
    }
  }
  .group-selector {
    padding-top: 16px;
    border-top: 1px solid $color__white;
    .label {
      margin-bottom: 16px;
    }
  }
}
