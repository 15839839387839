.modal-wrapper {
  z-index: 8;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;

  & > .modal-dim {
    width: 100%;
    height: 100%;
    opacity: 0.6;
    background-color: $color__default;
  }

  &.alert-error > .modal-dim {
    background-color: $color__white;
    opacity: 1;
  }

  & > .modal-content {
    & > .title {
      @include font__header_title;
      position: relative;
      padding: 0 20px;
      width: 100%;
      height: auto;
      display: flex;
      justify-content: space-between;
    }

    & > .close {
      padding: 12px;
      width: 100%;
      height: auto;
      display: flex;
      justify-content: flex-end;
      cursor: pointer;
    }

    & > .footer {
      padding: 10px 0;
      width: 100%;
      height: auto;
      display: flex;
      gap: 20px;

      &.is-border {
        border-top: 1px solid $color__lgrey_100;
      }

      & > button {
        width: 100%;
      }
    }
  }

  & .modal-item {
    @include font__body_2;
    display: flex;
    width: 100%;
    height: 66px;
    padding: 12px 14px;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    background-color: $color__lgrey_100;

    &-container {
      display: flex;
      flex-direction: column;
      gap: 4px;
      margin-bottom: 10px;
    }
  }

  &.alert {
    &.alert-error .close {
      visibility: hidden;
    }

    &.animation {
      animation-name: fadeIn;
      animation-duration: 0.3s;
    }

    & > .modal-content {
      @include positionCenter(absolute);
      padding-top: 30px;
      width: 300px;
      height: auto;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex-direction: column;
      border-radius: 12px;
      background: $color__white;
   

      & > .close,
      & > .title {
        padding: 0;
        justify-content: center;
        padding: 0px 20px;
        text-align: center;
        white-space: pre-line;
      }

      & > .footer {
        padding: 0;
        border-top: 1px solid $color__lgrey_100;
        margin-top: 10px;
        display: flex;
        // padding: 8px;
        width: 100%;
        justify-content: space-around;
        gap: 0px;
        cursor: pointer;

        & > div {
          width: 100%;
          padding: 16px 15px;
          text-align: center;
          &:not(:last-child) {
            box-shadow: 1px 0 0 $color__lgrey_100;
          }

          &.success {
            color: $color__p_purple;
          }
        }
      }

      & > .content {
        padding: 0 20px;
        text-align: center;
        color: $color__grey_600;
        white-space: pre-line;
        width: 100%;
      }
    }
  }

  &.normal {
    &.animation {
      animation-duration: 0.3s;
      animation-name: fadeIn;
    }

    & > .modal-content {
      @include positionCenter(absolute);
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 16px;
      // background: $color__white;
      // padding: 24px;
      height: 100%;
      box-shadow: 0px 24px 38px rgba(0, 0, 0, 0.14), 0px 9px 46px rgba(0, 0, 0, 0.12), 0px 11px 15px rgba(0, 0, 0, 0.2);
      border-radius: 2px;

      & > .close,
      & > .title,
      & > .footer {
        padding: 0;

        &.success {
          display: flex;
          justify-content: flex-end;
          color: $color__p_purple;
          width: 100%;
          padding: 8px;
          cursor: pointer;
        }
      }
    }
  }

  &.menu {
    & > .modal-content {
      &.animation {
        animation-name: slideUp;
        animation-duration: 0.3s;
      }

      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      margin: 0 auto;
      padding: 20px 20px 0 20px;
      width: 100%;
      height: auto;
      overflow-x: hidden;
      max-height: calc(100% - 20px);
      display: flex;
      flex-direction: column;
      border-radius: 16px 16px 0 0;
      background: $color__white;
      box-shadow: 0 -8px 16px rgba(0, 0, 0, 0.08);

      & > .title {
        padding: 0;
        margin-bottom: 10px;
        align-items: center;
      }

      & .close {
        height: 30px;
      }

      & > .content {
        @include font__body_6;
        color: $color__grey_66;
        padding-bottom: 20px;
        width: 100%;
        display: inline-flex;
        flex-direction: column;
        overflow-y: auto;
        gap: 20px;
        white-space: pre-line;
        height: 100%;
        overflow-y: auto;

        &.kakao-alimtalk {
          padding-top: 20px; // 카카오토 알림 메시지에 본문 상단 20px 여백 필요
        }
        & > label {
          display: flex;
          padding: 16px 4px;
          justify-content: space-between;
          align-items: center;
          flex-direction: row-reverse;
          border-bottom: 1px solid $color__lgrey_100;
          cursor: pointer;
        }
      }
    }
  }

  &.toast {
    &.animation {
      animation-name: fadeIn;
      animation-duration: 0.5s;
    }

    & > .modal-content {
      @include positionCenter(absolute);
      padding: 12px 14px;
      width: calc(100% - 40px);
      height: auto;
      border-radius: 4px;
      background: $color__default;
      text-align: center;

      & > .content {
        color: $color__white;
      }

      &.top {
        top: calc(0% + 40px);
      }

      &.bottom {
        top: calc(100% - 40px);
      }
    }
  }

  &.setting {
    &.animation {
      animation-name: slideUp;
      animation-duration: 0.3s;
    }

    & > .modal-content {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      margin: 0 auto;
      padding: 52px 20px 20px 20px;
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      border-radius: 20px 20px 0 0;
      background: $color__white;

      & > hr {
        height: 1px;
        width: calc(100% + 40px);
        margin: 20px -20px;
        border: 0;
        background-color: $color__lgrey_100;
      }

      & > div.content-wrapper {
        @include font__body_md;
      }

      & > div.button-wrapper {
        display: flex;
        gap: 8px;

        & > button {
          flex: 0.5;
          @include tblm-button-normal;
        }
      }

      &.continue {
        & > div.content-wrapper {
          @include font__body_md;

          & > .emphasis {
            @include font__title_32_sb;
            margin-top: 24px;
            margin-bottom: 28px;
            margin-right: 124px;
            word-break: keep-all;
          }
        }
      }
    }
  }

  &.check {
    &.animation {
      animation-name: slideUp;
      animation-duration: 0.3s;
    }

    & > .modal-content {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      margin: 0 auto;
      padding: 20px;
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      border-radius: 16px 16px 0 0;
      background: $color__white;
      white-space: pre-line;

      & > .title {
        padding: 0;
        margin-bottom: 30px;
        align-items: center;
      }

      & > .tabletCustomInformationMessage {
        color: $color__grey_66;
      }

      & > div.content-wrapper {
        @include font__body_md;
      }

      & > div.button-wrapper {
        display: flex;
        gap: 8px;
        margin-top: 40px;

        & > button {
          flex: 1;
          @include tblm-button-normal;
        }
      }

      &.continue {
        & > div.content-wrapper {
          @include font__body_md;

          & > .emphasis {
            @include font__title_32;
            margin-top: 24px;
            margin-bottom: 28px;
            margin-right: 124px;
            word-break: keep-all;
          }
        }
      }
    }
  }
}
