/* responsive */
$breakpoint__mobile: 360px;
$breakpoint__tablet: 768px;
$breakpoint__desktop: 1024px;
$breakpoint__xlg: 1280px;

$min-width: 320px;

/* font-family */
$font_default_alternative_family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, 'Helvetica Neue', 'Segoe UI', 'Apple SD Gothic Neo', 'Malgun Gothic',
  'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
$font_default_family: 'Roboto', 'Noto Sans KR', $font_default_alternative_family;

$font__size11: 11px;
$font__size12: 12px;
$font__size13: 13px;
$font__size15: 15px;
$font__size17: 17px;
$font__size19: 19px;
$font__size20: 20px;

/* font-weight */
$font__400: 400;
$font__500: 500;
$font__600: 600;
$font__700: 700;
$font__regular: $font__400;
$font__semibold: $font__600;
$font__bold: $font__700;

/* colors */
$color__default: #2e2e30;
// Primary Color
$color__p_purple: #6931e0;
$color__p_dpurple: #40169a;
$color__p_purple_dark: #251754;
$color__p_purple_dimmed: #c4aff0;
$color__p_purple_light: #f2eefc;
$color__p_sky: #5cb5fd;
$color__p_green: #3dcf94;
// Secondary Color
$color__s_orange: #ff8d3b;
$color__s_green: #1d9772;
$color__s_green_light: #dbf8ec;
$color__s_coral: #e35b5b;
$color__s_red: #ff6161;
$color__s_red_light: #ffe5e5;
// Gray_Black Color
$color__black: #212121;
$color__black_1d: #1d1d1d;
$color__grey_66: #7e7b83;
$color__grey_900: #2e2e30;
$color__grey_600: #8a8395;
$color__lgrey_300: #cbc8cf;
$color__lgrey_100: #eeedf1;
$color__lgrey_50: #f4f4f4;
$color__bg_98: #f7f8f9;
$color__white: #ffffff;
// Error Color
$color_error: #ff6161;
// Gradient
$color_gra_blue: linear-gradient(89.88deg, #297cf6 0.1%, #1344cb 99.89%);
$color_gra_lavender: rgba(0, 18, 178, 0.4);
