.kakao-setting-wrapper {
  width: 100dvw;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: $color__white;

  &.modaled {
    position: fixed;
    top: 0;
    z-index: 3;
  }

  .kakao-setting {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow-y: auto;

    &.kakao-channel {
      padding-top: 20px;
    }

    &.kakao-channel,
    &.kakao-mall-message {
      gap: 20px;
      padding-bottom: 88px;
    }

    &-item {
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 0 20px;

      &-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
        @include font(16px, $color__default, 700, normal);

        .required {
          &:after {
            content: '(필수)';
            margin-left: 4px;
            @include font(16px, #ff6161, 400, normal);
          }
        }

        .item-action-button {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 8px 14px;
          background-color: $color__lgrey_100;
          border-radius: 40px;
          border: 0;
          cursor: pointer;
          @include font(14px, $color__default, 500, normal);

          &:hover:active {
            background-color: $color__lgrey_300;
          }
        }
      }

      &-image {
        max-height: 240px;

        img {
          max-height: 120px;
        }
      }

      .kakao-channel-profile-image {
        width: 160px;
        height: 160px;
        // margin: 0 auto;
        align-self: center;
        border-radius: 60px;

        &-warning {
          display: flex;
          align-items: center;
          gap: 2px;
          margin-top: 10px; // Gap 10px이 있어 + 10px만 처리

          .text {
            line-height: normal;
            color: #ff6161;
          }
        }
      }
    }

    &-menu {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 20px;
      padding: 16px 20px;
      cursor: pointer;

      &-content {
        display: flex;
        flex-direction: column;
        gap: 2px;

        .title {
          margin: 0;
          @include font(16px, $color__default, 500, normal);
        }

        .tabletCustomInformationMessage {
          margin: 0;
          @include font(14px, $color__grey_66, 400, normal);
        }
      }

      // svg {
      //   min-width: fit-content;
      //   min-height: fit-content;
      // }

      &:not(:last-child) {
        border-bottom: 1px solid $color__lgrey_50;
      }
    }

    &-divider {
      height: 1px;
      background-color: $color__lgrey_50;

      &:not(&.w-full) {
        margin: 0 20px;
      }
    }

    &-button-wrapper {
      display: flex;
      align-items: center;
      gap: 10px;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 20px;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%);

      button {
        flex: 1;
        height: 48px;
        padding: 14px;
        border-radius: 4px;
        @include font(14px, $color__default, 700, 18px);
        cursor: pointer;
      }

      .tblm-btn-secondary {
        border: 1px solid $color__lgrey_300;
        background-color: #fff;

        &:hover {
          background-color: $color__lgrey_50;
        }

        &:active {
          background-color: $color__lgrey_100;
        }
      }
    }
  }
}

.kakao-channel-profile-edit {
  width: 100%;

  &>.kakao-channel-profile-image-edit {
    width: 100%;
    height: unset;
    aspect-ratio: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffe500;
    border-radius: 33dvw;
  }

  & textarea {
    width: 100%;
    height: 20vw;
    border: 0;
    box-sizing: border-box;
    border-radius: 4px;
    background-color: transparent;
    transition: border 100ms;
    font-size: 18vw;
    font-weight: 700;
    color: #2e2e30;
    line-height: 21vw;
    display: inline-block;
    text-align: center;
    vertical-align: central;
    overflow-y: hidden;
    padding: 0 1vw;

    &.small-oneline {
      height: 18vw;
      font-size: 16vw;
      line-height: 19vw;
    }

    &.twoline {
      height: 37vw;
      font-size: 16vw;
      line-height: 19vw;
      padding: 0 5vw;
    }

    &.small-twoline {
      height: 29vw;
      font-size: 13vw;
      line-height: 15vw;
      padding: 0 8vw;
    }

    &.small-threeline {
      height: 44.5vw;
      font-size: 13vw;
      line-height: 15vw;
      padding: 0 8vw;
    }
  }
}

.kakao-text-image-edit {
  width: 100%;
  background-color: #f9f9f9;

  &>.kakao-text-image-image.dummy {
    width: 100%;
    aspect-ratio: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &>svg {
      position: absolute;
      right: 8px;
      bottom: 6px;
      width: 81px;
      height: 81px;
    }
  }

  & textarea {
    width: 100%;
    height: 41vw;
    border: 0;
    box-sizing: border-box;
    background-color: transparent;
    transition: border 100ms;
    font-size: 6.7vw;
    font-weight: 700;
    color: #2e2e30;
    line-height: 9.4vw;
    display: inline-block;
    overflow-y: hidden;
    padding: 0 28vw 0 5vw;

    &::placeholder {
      color: $color__grey_600;
    }

    // &.small-oneline {
    //   height: 18vw;
    //   font-size: 16vw;
    //   line-height: 19vw;
    // }
    // &.twoline {
    //   height: 37vw;
    //   font-size: 16vw;
    //   line-height: 19vw;
    //   padding: 0 5vw;
    // }
    // &.small-twoline {
    //   height: 29vw;
    //   font-size: 13vw;
    //   line-height: 15vw;
    //   padding: 0 8vw;
    // }
    // &.small-threeline {
    //   height: 44.5vw;
    //   font-size: 13vw;
    //   line-height: 15vw;
    //   padding: 0 8vw;
    // }
  }
}

.modal-wrapper.menu {
  .kakaotalk-notitalk {
    justify-content: center;
    align-items: center;
    display: inline-flex;
    width: 100%;
    // margin-top: 20px;

    &>.kakaotalk-notitalk-container {
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      display: inline-flex;
      width: 240px;

      &>.kakaotalk-notitalk-title-container {
        width: 100%;
        padding: 10px;
        background: #ffe500;
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
        justify-content: center;
        align-items: center;
        display: inline-flex;
      }

      &>.kakaotalk-notitalk-message-container {
        display: inline-flex;
        flex-direction: column;
        width: 100%;
        align-self: stretch;
        padding: 18px 16px;
        gap: 10px;
        background: $color__white;
        border-radius: 0 0 16px 16px;
        border-left: 1px solid $color__lgrey_100;
        border-right: 1px solid $color__lgrey_100;
        border-bottom: 1px solid $color__lgrey_100;

        >.kakaotalk-message-create {
          &-header {
            @include font__body_2;
            color: #191919;
          }

          &-waiting-number {
            display: flex;
            gap: 10px;
            justify-content: space-between;

            >div {
              display: grid;
              padding-block: 16px;
              gap: 4px;

              >.waiting-number {
                @include font__body_6;
                color: #191919;

                &-label {
                  color: #8e8e8e;
                }
              }
            }

            >svg {
              align-self: center;
            }
          }
        }

        >.kakaotalk-message-body-info {
          padding-block: 8px;

          ul {
            display: grid;
            grid-template-columns: 1.2fr 2fr;
            column-gap: 10px;
            flex-direction: column;
            row-gap: 4px;
            @include font__body_6;

            >span:nth-child(even) {
              color: #191919;
            }

            >span:nth-child(odd) {
              color: #8e8e8e;
            }
          }

          li {
            // display: flex;
            // align-items: flex-start;
            gap: 4px;
            // align-self: stretch;

            span:first-child {
              @include font__small;
              color: #8e8e8e;
              min-width: 24px;
            }

            span:not(first-child) {
              @include font__small;
              color: #191919;
            }
          }
        }
      }

      & .kakaotalk-message-header {}

      & .kakaotalk-notitalk-message {
        @include font__body_6;
        color: #191919;
        word-wrap: break-word;
      }
    }
  }

  .kakaotalk-message-area {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;

    .kakaotalk-message-profile {
      width: 48px;
      height: 48px;

      .kakaotalk-text-profile {
        @include font__s_small_b;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        border-radius: 16px;
        background: #ffe500;
        word-wrap: break-word;
        word-break: keep-all;
      }

      img {
        width: 48px;
        height: 48px;
        border-radius: 16px;
      }
    }

    .kakaotalk-message-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;

      .kakaotalk-message-nickname {
        @include font__s_small_m;
        color: #5c6770;
      }

      .kakaotalk-message {
        width: 240px;

        >.kakaotalk-message-header {
          border-radius: 16px 16px 0px 0px;
          width: 240px;
          height: 120px;

          img {
            border-radius: 16px 16px 0px 0px;
            width: 240px;
            height: 120px;
          }
        }

        >.kakaotalk-message-body-container {
          display: flex;
          padding: 12px 16px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 12px;
          align-self: stretch;
          border-radius: 0px 0px 16px 16px;
          border-right: 1px solid $color__lgrey_100;
          border-bottom: 1px solid $color__lgrey_100;
          border-left: 1px solid $color__lgrey_100;
          background: $color__white;

          >.kakaotalk-message-body-title {
            align-self: stretch;
            @include font__body_5;
            color: #191919;
          }

          >.divider {
            height: 1px;
            background-color: $color__lgrey_50;

            &:not(&.w-full) {
              margin: 0 20px;
            }
          }

          >.kakaotalk-message-body-info {
            ul {
              display: flex;
              flex-direction: column;
              gap: 6px;
            }

            li {
              display: flex;
              align-items: flex-start;
              gap: 4px;
              align-self: stretch;

              span:first-child {
                @include font__small;
                color: #8e8e8e;
                min-width: 24px;
              }

              span:not(first-child) {
                @include font__small;
                color: #191919;
              }
            }
          }

          >.kakaotalk-message-body-content {
            @include font__body_6;
            color: #191919;
            white-space: pre-line;
            overflow-wrap: break-word;
            width: 208px;
          }

          >.kakaotalk-message-body-footer {
            display: flex;
            align-items: flex-start;
            gap: 4px;
            align-self: stretch;

            .button {
              @include font__small_m;
              display: flex;
              height: 36px;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              flex: 1 0 0;
              border-radius: 6px;
              background: rgba(0, 0, 0, 0.06);

              &.primary {
                background: #ffe500;
              }
            }
          }
        }
      }

      .noti-off {
        @include font__small_m;
        color: #000;
        text-align: center;

        display: flex;
        height: 36px;
        padding: 0px 16px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 18px;
        border: 1px solid $color__lgrey_100;
        background: $color__white;
      }
    }
  }
}