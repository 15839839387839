@import '../../styles/utils/variables';
@import '../../styles/utils/boxshadow';
@import '../../styles/utils/filters';
@import '../../styles/utils/mixin';
@import '../../styles/utils/responsive';
@import '../../styles/utils/textstyle';

@import '../../styles/presets/buttons';

.header-sub-type-wrapper {
  position: sticky;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 20px;
  left: 0;
  right: 0;
  z-index: 2;
  background: $color__white;

  .header-text {
    flex: 1;
  }
  &.header-sub {
    z-index: 2;
    top: 0;
    & > .close {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 16px 0;
      gap: 16px;
    }
    & > .title {
      display: flex;
      width: 100%;
      padding: 0 0 24px 0;
      &.remove-br {
        & br {
          display: none;
        }
      }
    }
  }
}
.header-placeholder {
  width: 100%;
  height: 140px;
  background-color: #fff;
}
