label.tblm-rc {
  cursor: default;
  user-select: none;
  & > label {
    display: flex;
    align-items: center;
  }
  & > input {
    display: none;
    & + i {
      display: inline-block;
      // transform: translateY(-1.5px);
    }
    &[type='radio'] + i {
      width: 20px;
      height: 20px;
      background-image: url(/assets/images/icons/Radio.svg);
    }
    &[type='checkbox'] {
      & ~ span {
        @include font__body_2;
      }
      & + i {
        width: 20px;
        height: 20px;
        background-image: url(/assets/images/icons/CheckBox.svg);
      }
    }
    &:checked {
      &[type='radio'] + i {
        background-image: url(/assets/images/icons/Radio_selected.svg);
      }
      &[type='checkbox'] {
        & + i {
          background-image: url(/assets/images/icons/CheckBox_Selected.svg);
        }
      }
    }
    &:disabled {
      &[type='checkbox'] + i {
        width: 20px;
        height: 20px;
        background-image: url(/assets/images/icons/CheckBox_Dimmed.svg);
      }
    }
  }
}

label.tblm-rc.small-checkbox {
  & > input {
    &[type='checkbox'] + i {
      width: 16px;
      height: 16px;
      min-width: 16px;
      min-height: 16px;
      background-size: 100%;
    }
    &:checked {
      &[type='checkbox'] + i {
        background-size: 100%;
      }
    }
  }
}

label.tblm-switch {
  width: 56px;
  height: 30px;
  display: inline-block;
  z-index: 1;
  & > input[type='checkbox'] {
    display: none;
    & + i {
      position: relative;
      width: 100%;
      height: 100%;
      display: inline-flex;
      align-items: center;
      &::before {
        content: '';
        width: 100%;
        height: 30px;
        display: inline-block;
        border-radius: 15px;
        background-color: $color__lgrey_300;
        transition: 0.5s background-color;

        will-change: background-color;
      }
      &::after {
        content: '';
        position: absolute;
        top: 3px;
        left: 3px;
        width: 24px;
        height: 24px;
        display: block;
        border-radius: 12px;
        background-color: $color__white;
        box-shadow: 0 0 1px rgba(0, 0, 0, 0.24), 0 1px 2px rgba(0, 0, 0, 0.08);
        transition: 0.5s all;

        will-change: background-color, left;
      }
    }
    &:checked + i {
      &::before {
        background-color: $color__p_green;
      }
      &::after {
        top: 3px;
        left: calc(100% - 27px);
        transition: 0.5s background-color, 0.5s left;
      }
    }
  }
}

/*
Usage)
!! tblm-rc depend on type
!! tblm-switch only with checkbox

<label class="tblm-rc"><input type="radio" name="radio_example" checked="checked"><i /></label>
<label class="tblm-rc"><input type="checkbox" name="checkbox_example" checked="checked"><i /></label>
<label class="tblm-switch"><input type="checkbox" name="switch_example" checked="checked"><i /></label>
*/
