@import '../../../styles/utils/variables';
@import '../../../styles/utils/boxshadow';
@import '../../../styles/utils/filters';
@import '../../../styles/utils/mixin';
@import '../../../styles/utils/responsive';
@import '../../../styles/utils/textstyle';

@import '../../../styles/presets/buttons';

.reservation-off-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: $color__lgrey_50;
  margin-top: 106px;

  &-submit {
    width: 100%;
    padding: 20px;
    margin-top: auto;
    box-sizing: border-box;
    background-color: $color__white;

    &-btn {
      position: fixed;
      left: 20px;
      right: 20px;
      bottom: 20px;
      z-index: 1;
      background-color: $color__p_purple;
      padding: 14px;
      border: unset;
      border-radius: 4px;
      color: $color__white;
      font-size: 14px;
      font-weight: 700;

      &-bg-gradient {
        width: calc(100%); // left, right 값 반영
        display: none;
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%);

        &.show-background {
          display: initial;
        }
      }
    }
  }
}
